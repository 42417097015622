// ==============================|| OVERRIDES - TABLE CELL ||============================== //

export default function TableCell(theme) {
  return {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: theme.palette.grey[200],
          textTransform: 'uppercase',
          verticalAlign: 'middle',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
          padding: 6,
          borderColor: theme.palette.divider,
          verticalAlign: 'middle',
        },
        head: {
          fontWeight: 600,
          paddingTop: 10,
          paddingBottom: 10,
        },
      },
    },
  }
}
