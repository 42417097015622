import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../utils/paginationUtil'
const initialState = {
  assetCategories: null,
  assetSubCategories: null,
  metaData: null,
  dashboardCount: null,
  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
  getAssetList: [],
  getAllAssetList: [],
  postSegment: null,
  getAssetById: null,
  getAllImages: [],
  getAllFonts: [],
  getSingleImage: [],

  fontPageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },

  imagePageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
}

const assetSlice = createSlice({
  name: 'assetSlice',
  initialState: initialState,
  reducers: {
    getAssetCategories(state, action) {
      state.assetCategories = action.payload
    },
    getAssetSubCategories(state, action) {
      state.assetSubCategories = action.payload
    },

    sendContentTags(state, action) {
      state.metaData = action.payload
    },

    getAsset(state, action) {
      state.getAssetList = action.payload
    },

    getAllAsset(state, action) {
      state.getAllAssetList = action.payload.response.data.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.response.data.pageNo,
        pageSize: action.payload.response.data.pageSize,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },
    GetSingleImage(state, action) {
      state.getSingleImage = action.payload
    },

    GetAssetData(state, action) {
      state.getAssetById = action.payload.response.data
    },
    createSegment(state, action) {
      state.postSegment = action.payload
    },

    getImages(state, action) {
      state.getAllImages = action.payload.content
      state.imagePageable = {
        ...state.imagePageable,
        pageNo: action.payload.pageNo,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      }
    },
    getFont(state, action) {
      state.getAllFonts = action.payload.content
      state.fontPageable = {
        ...state.fontPageable,
        pageNo: action.payload.pageNo,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      }
    },
    getFonts(state, action) {
      state.getAllFonts = action.payload
    },

    getDashboardCount(state, action) {
      state.dashboardCount = action.payload
    },
  },
})

export default assetSlice
