import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import {
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'

function SelectField(props) {
  const { label, name, options, readOnly=false, defaultValue, ...rest } = props

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <FormControl fullWidth error={meta.error && meta.touched}>
          <InputLabel>{label}</InputLabel>
          <Select {...field} {...rest} label={label}
          readOnly={readOnly}
            InputProps={{
              readOnly: readOnly,
              style: {
                color: readOnly ? '#333333' : 'inherit',
                backgroundColor: readOnly ? '#eeeeee' : 'inherit',

              },
            }}
           
            InputLabelProps={{
             
              style: {
                color: readOnly ? '#333333' : 'inherit',
                backgroundColor: readOnly ? '#eeeeee' : 'inherit',
              },
            }}

            // disabled={readOnly}
          
            value={field.value || defaultValue || ''}>
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.key}
              </MenuItem>
            ))}

          </Select>
          <ErrorMessage component={TextError} name={name} />
        </FormControl>
      )}
    </Field>
  )
}

export default SelectField
