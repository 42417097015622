import React, { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Chip,
  FormControl,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import {
  StyledTableCell,
  StyledTableRow,
} from '../../../../ui-components/basic-table'
import TextError from '../../../../ui-components/form/formik/TextError'
import AutocompleteField from '../../../../ui-components/form/MuiAutocompleteField'
import ClearIcon from '@mui/icons-material/Clear'

const CategoriesField = ({
  categories,
  subcategories,
  assetCategoryAction,
  assetSubCategoryAction,
  ...props
}) => {
  const [showForm, setShowForm] = useState(false)
  const [category, setCategory] = React.useState('')
  const [subCategories, setSubCategories] = React.useState([])
  const { values, setFieldValue } = useFormikContext()

  const handleChangeCategory = category => {
    const selectedCategory = categories?.find(
      item => item.categoryName === category
    )
    const categoryId = selectedCategory?.id
    setCategory(category)
    setSubCategories([])
    assetSubCategoryAction(categoryId)
  }

  const handleChangeSubCategory = subcategories => {
    setSubCategories(subcategories)
  }

  const handleUpdateCategoryMap = (values, setFieldValue) => {
    if (!category || subCategories.length === 0) return

    setFieldValue('categories', {
      ...values.categories,
      [category]: subCategories,
    })

    setCategory('')
    setSubCategories([])
  }

  useEffect(() => {
    assetCategoryAction()
  }, [assetCategoryAction])

  //  const  handleDelete=(category)=>{

  //   const newArray = categories.filter(item => Object.keys(item)[0] !== category);

  //   console.log(newArray);

  //  }
  return (
    <Field name="categories">
      {({ field, form, meta }) => (
        <FormControl error={meta.error && meta.touched}>
          <Box>
            <Typography variant="h4" pb={1}>
              {' '}
              Categories<span style={{ color: 'red' }}>*</span>
            </Typography>

            <TableContainer component={Paper}>
              <Table>
                {!showForm ? (
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        sx={{
                          fontWeight: 'bold',
                          width: '45%',
                        }}
                      >
                        Key
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontWeight: 'bold',
                          width: '45%',
                        }}
                      >
                        Values{' '}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          fontWeight: 'bold',
                          width: '20%',
                        }}
                      >
                        <Button onClick={() => setShowForm(true)}>Add</Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                ) : (
                  <>
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell
                          sx={{
                            fontWeight: 'bold',
                            width: '45%',
                          }}
                        >
                          {/* <InputLabel id="category">
                              Select Category key
                            </InputLabel> */}
                          <AutocompleteField
                            value={category}
                            options={
                              categories?.map(op => ({
                                label: op.categoryName,
                                value: op.categoryName,
                              })) || []
                            }
                            onChange={tags => handleChangeCategory(tags)}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontWeight: 'bold',
                            width: '45%',
                          }}
                        >
                          {/* <InputLabel id="category">
                              Select Category values
                            </InputLabel> */}
                          {category ? (
                            // <MultiStep props={subcatgories} />
                            <AutocompleteField
                              isMulti={true}
                              value={subCategories}
                              options={
                                subcategories?.map(op => ({
                                  label: op.subCategoryName,
                                  value: op.subCategoryName,
                                })) || []
                              }
                              onChange={tags => handleChangeSubCategory(tags)}
                            />
                          ) : (
                            'No value'
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          sx={{
                            fontWeight: 'bold',
                            width: '10%',
                          }}
                        >
                          <Stack direction="row" spacing={2}>
                            <Button
                              onClick={() => {
                                handleUpdateCategoryMap(values, setFieldValue)
                              }}
                            >
                              Add
                            </Button>
                            <Button onClick={() => setShowForm(false)}>
                              Cancel
                            </Button>
                          </Stack>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                  </>
                )}
                <TableBody>
                  {values.categories &&
                    Object?.keys(values?.categories).map(category => (
                      <StyledTableRow
                        key={category}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          <Typography variant="h4">{category}</Typography>
                        </StyledTableCell>
                        <StyledTableCell>
                          {values?.categories[category].map(sub => (
                            <Chip
                              key={sub}
                              label={sub}
                              sx={{ marginRight: '8px' }}
                            />
                          ))}
                        </StyledTableCell>
                        <StyledTableCell align="right"></StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <ErrorMessage component={TextError} name={field.name} />
        </FormControl>
      )}
    </Field>
  )
}

export default CategoriesField
