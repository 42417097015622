import React, { useState } from 'react'
import { CardContent, Typography } from '@mui/material'
import AssetForm from '../asset-form'
import { connect } from 'react-redux'
import { MetadataAction } from '../redux/assetAction'
import { toast } from 'react-toastify'
import MainCard from '../../../components/cards/MainCard'

const AssetNewPage = ({ ...props }) => {
  const [assetData, setAssetData] = useState({
    name: '',
    batch: '',
    tags: [],
    categories: {},
    categoryList: [],
  })

  const handleSubmit = formData => {
    return props
      .MetadataAction(formData)
      .then(response => {
        const AssetId = response?.data?.id
        localStorage.setItem('assetId', AssetId)
        if (response?.status == 200) {
          toast.success('Metadata submitted successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
          return Promise.resolve()
        } else {
          toast.error('Something went wrong', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
          return Promise.reject()
        }
      })
      .catch(error => {
        toast.error('Something went wrong', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
        return Promise.reject()
      })
  }

  return (
    <MainCard title={<Typography variant="h3">Create Public Asset</Typography>}>
      <CardContent>
        <AssetForm assetData={assetData} handleSubmit={handleSubmit} />
      </CardContent>
    </MainCard>
  )
}

const mapStateToProps = state => ({})

const mapActionsToProps = {
  MetadataAction,
}

export default connect(mapStateToProps, mapActionsToProps)(AssetNewPage)
