import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants/TemplateConstant'
const initialState = {
  getTemplateList: [],
  get_template_list: [],
  get_all_templates: [],
  get_single_template: null,
  get_template_csv: [],
  get_template_instances: [],
  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
}

const templateSlice = createSlice({
  name: 'templateSlice',
  initialState: initialState,
  reducers: {
    getTemplate(state, action) {
      state.getTemplateList = action.payload
    },
    getTemplateList(state, action) {
      state.get_template_list = action.payload.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.pageNo,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      }
    },
    getSingleTemplate(state, action) {
      state.get_single_template = action.payload
    },
    getTemplateCsv(state, action) {
      state.get_template_csv = action.payload
    },
    getTemplateInstance(state, action) {
      state.get_template_instances = action.payload
    },
    sendContentTags(state, action) {
      state.metaData = action.payload
    },
    getTemplates(state, action) {
      state.get_all_templates = action.payload
    },
  },
})

export default templateSlice
