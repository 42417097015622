import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants'

const initialState = {
  post_tags: [],
  get_tags: [],
  get_all_tags: [],
  get_single_tag: null,
  post_identifier: [],
  get_identifier: [],
  get_all_identifier: [],
  get_single_identifier: null,
  get_all_compositiontags: [],
  get_compositiontags: [],
  get_compositiontag: {},

  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
}

const entitySlice = createSlice({
  name: 'entitySlice',
  initialState: initialState,
  reducers: {
    setEntity: (state, action) => {
      state.tags = action.payload
    },

    getAllTags: (state, action) => {
      state.get_all_tags = action.payload
    },
    getSingleTag: (state, action) => {
      state.get_single_tag = action.payload
    },

    getTags: (state, action) => {
      state.get_tags = action.payload.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.pageNo,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      }
    },

    setIdentifier: (state, action) => {
      state.post_identifier = action.payload
    },

    getAllIdentifier: (state, action) => {
      state.get_all_identifier = action.payload
    },

    getSingleIdentifier: (state, action) => {
      state.get_single_identifier = action.payload
    },

    getIdentifier: (state, action) => {
      state.get_identifier = action.payload.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.pageNo,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      }
    },
    getAllCompositionTags: (state, action) => {
      state.get_all_compositiontags = action.payload.content

      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.pageNo || defaultPage,
        pageSize: action.payload.pageSize || defaultSize,
        totalElements: action.payload.totalElements || defaultTotalElements,
        totalPages: action.payload.totalPages || defaultTotalPages,
        search: action.payload.search,
      }
    },

    getCompositionTags: (state, action) => {
      state.get_compositiontags = action.payload
    },
    getCompositionTag: (state, action) => {
      state.get_compositiontag = action.payload
    },
  },
})

export default entitySlice
