import React from 'react'
import { Field } from 'formik'
import { FormControl, TextField, useMediaQuery } from '@mui/material'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import dayjs from 'dayjs'

function MobileTimePickerField({ label, formIk, ...rest }) {
  const { field, form, meta } = formIk

  const [value, setValue] = React.useState(
    field.value ? dayjs(field.value) : ''
  )

  const handleChange = newValue => {
    setValue(newValue)
    form.setFieldValue(
      field.name,
      newValue ? dayjs(newValue).format(rest.inputFormat) : '',
      true
    )
  }

  return (
    <TimePicker
      {...field}
      {...rest}
      value={value}
      error={false}
      inputFormat={rest.inputFormat}
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          onBlur={field.onBlur}
          error={meta.error && meta.touched && true}
          helperText={meta.touched && meta.error}
          label={label}
          name={field.name}
          fullWidth
        />
      )}
    />
  )
}

function DesktopTimePickerField({ label, formIk, ...rest }) {
  const { field, form, meta } = formIk

  const [value, setValue] = React.useState(
    field.value ? dayjs(field.value) : ''
  )

  const handleChange = newValue => {
    setValue(newValue)
    form.setFieldValue(
      field.name,
      newValue ? dayjs(newValue).format(rest.inputFormat) : '',
      true
    )
  }

  return (
    <TimePicker
      {...field}
      {...rest}
      value={value}
      error={false}
      inputFormat={rest.inputFormat}
      onChange={handleChange}
      renderInput={params => (
        <TextField
          {...params}
          onBlur={field.onBlur}
          error={meta.error && meta.touched && true}
          helperText={meta.touched && meta.error}
          label={label}
          name={field.name}
          fullWidth
        />
      )}
    />
  )
}

function MuiTimePicker({ name, ...props }) {
  const smDown = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <Field name={name}>
      {formIk => (
        <FormControl
          fullWidth
          error={Boolean(formIk.meta.error) && formIk.meta.touched}
        >
          {smDown ? (
            <MobileTimePickerField {...props} formIk={formIk} />
          ) : (
            <DesktopTimePickerField {...props} formIk={formIk} />
          )}
        </FormControl>
      )}
    </Field>
  )
}

export default MuiTimePicker
