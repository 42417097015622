import React, { useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { connect } from 'react-redux'
import {
  getCategoryAction,
  getAllSubCategory,
  getSubCategoryAction,
  postSubCategory,
  updateSubCategoryAction,
  deleteSubCategoryAction,
} from '../category/redux/categoryAction'
import {
  TextField,
  Button,
  Modal,
  FormControl,
  InputLabel,
} from '@mui/material'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Select,
  Stack,
  CircularProgress,
} from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { ErrorMessage, Formik, Form } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import MuiTable from '../../ui-components/mui-table/MuiTable'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom'
import {
  defaultPage,
  defaultSize,
  defaultSortBy,
  defaultSortDir,
  defaultTotalElements,
  defaultSearch,
} from '../../constants'

function SubCategoryList({ pageable, ...props }) {
  const [createDialog, setCreateDialog] = useState(false)
  const [editDialog, setEditDialog] = useState(false)
  const [deleteDialog, setDeleteDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editData, setEditData] = useState(null)
  const [subCatId, setSubCatId] = useState(null)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [pagination, setPagination] = useState({
    pageNo: +searchParams.get('page') || defaultPage,
    totalElements: +searchParams.get('totalElements') || defaultTotalElements,
    pageSize: +searchParams.get('size') || defaultSize,
    sortBy: searchParams.get('sort') || defaultSortBy,
    sortDir: searchParams.get('order') || defaultSortDir,
    search: searchParams.get('search') || '',
  })

  const columns = [
    {
      id: 'subCategoryName',
      label: 'SubCategory Name',
      align: 'left',
      alignHeader: 'left',
      formatter: row => {
        return row?.subCategoryName
      },
    },

    {
      id: 'actions',
      label: 'Actions',
      align: 'center',
      alignHeader: 'left',
      // formatter: ActionFormatter,
      formatter: row => {
        return (
          <>
            <Stack direction="row" spacing={1} sx={{ height: '35px' }}>
              <EditIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => handleEditDialog(row)}
              />
              <DeleteIcon
                sx={{ cursor: 'pointer' }}
                onClick={() => handleDeleteDialog(row)}
              />
            </Stack>
          </>
        )
      },
    },
  ]
  const handleClose = () => {
    setCreateDialog(false)
  }

  const handleEditDialog = item => {
    setEditData(item)
    setEditDialog(true)
  }

  const handleDeleteDialog = item => {
    setSubCatId(item)
    setDeleteDialog(true)
  }

  function handleSubCategoryValues(values) {
    const id = values.category
    const data = { subCategoryName: values.subCategoryName }
    props
      .postSubCategory(id, data)
      .then(res => {
        if (res.status === 200) {
          setLoading(false)
          toast.success('SubCategory added successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
          props.getAllSubCategory(pagination)
          handleClose()
        } else {
          setLoading(false)
          toast.error('error')
        }
        // window.location.reload();
      })
      .catch(err => {
        setLoading(false)
        toast.error('Something went wrong ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
      })
  }

  const handleSubCategoryUpdateSubmit = ({ subCategoryName, categoryId }) => {
    const id = editData?.id

    const data = { subCategoryName: subCategoryName, categoryId: categoryId }
    props
      .updateSubCategoryAction(id, data)
      .then(res => {
        if (res.status === 200) {
          toast.success('SubCategory Updated successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
          props.getAllSubCategory(pagination)
        } else {
          toast.error('error')
        }
        setEditDialog(false)
      })
      .catch(err => {
        toast.error('Something went wrong', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
      })
  }

  const deleteSubCategoryItem = values => {
    const id = subCatId?.id
    props
      .deleteSubCategoryAction(id)
      .then(res => {
        if (res.status === 200) {
          toast.success('SubCategory Deleted successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
          props.getAllSubCategory(pagination)
        } else {
          toast.error('error')
        }
        setDeleteDialog(false)
      })
      .catch(err => {
        toast.error('Something went wrong', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
      })
  }

  useEffect(() => {
    props.getCategoryAction()
    props.getAllSubCategory(pagination)
  }, [searchParams])

  const handleTableChange = (type, params) => {
    const queryParams = {
      page: params.page,
      size: params.rowsPerPage,
      sort: params.orderBy,
      order: params.order,
      search: params.search,
    }

    setPagination({
      pageNo: params.page,
      pageSize: params.rowsPerPage,
      sortBy: params.orderBy,
      sortDir: params.order,
      search: params.search,
    })

    navigate({
      pathname: '/subcategory/list',
      search: `?${createSearchParams(queryParams)}`,
    })
  }
  return (
    <>
      <Grid iten xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
              <Typography variant="h3">Manage SubCategory</Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'right',
                justifyContent: 'right',
                ml: { xs: 0, md: 1 },
              }}
            >
              <FormControl
                sx={{
                  width: '50%',
                  marginRight: '10px',
                }}
              >
                {/* <OutlinedInput
                  size="small"
                  id="header-search"
                  startAdornment={
                    <InputAdornment position="start" sx={{ mr: -0.5 }}>
                      <SearchOutlined />
                    </InputAdornment>
                  }
                  aria-describedby="header-search-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  placeholder=" Search subCategory"
                />*/}
              </FormControl>
              <Button
                variant="contained"
                onClick={() => {
                  setCreateDialog(true)
                }}
              >
                Add SubCategory
              </Button>
            </Box>
          </div>

          {/* ========= Dialog for create subcategory ========= */}
          <Dialog
            open={createDialog}
            onClose={handleClose}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle> SubCategory </DialogTitle>
            <Formik
              enableReinitialize={true}
              initialValues={{
                subCategoryName: '',
                category: '',
              }}
              validationSchema={yup.object().shape({
                subCategoryName: yup
                  .string()
                  .required('SubCategoryName is required'),
                category: yup.string().required('Category is required'),
              })}
              onSubmit={handleSubCategoryValues}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <>
                  <form onSubmit={handleSubmit}>
                    <DialogContent sx={{ width: '600px' }}>
                      <DialogContentText></DialogContentText>
                      <Box></Box>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Category Name
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          //value={categoryName}
                          label="CategoryName"
                          name="category"
                          onChange={handleChange}
                          error={Boolean(touched.category && errors.category)}
                        >
                          {props.category &&
                            props?.category.map(item => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.categoryName}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>

                      <Box sx={{ minWidth: 120, mt: 2 }}>
                        <TextField
                          margin="dense"
                          id="outlined-basic"
                          label="SubCategoryName"
                          name="subCategoryName"
                          type="text"
                          fullWidth
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          style={{
                            color: 'red',
                          }}
                          error={Boolean(
                            touched.subCategoryName && errors.subCategoryName
                          )}
                          //onChange={(event) => {
                          //  setSubCategoryName(event.target.value);
                          //}}
                        ></TextField>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        endIcon={
                          loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            ''
                          )
                        }
                        //onClick={() => createNewSubCategory()}
                      >
                        SubCategory
                      </Button>
                    </DialogActions>
                  </form>
                </>
              )}
            </Formik>
          </Dialog>

          {/* ========= Dialog for Edit subcategory ========= */}
          <Dialog
            open={editDialog}
            onClose={() => setEditDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogTitle> SubCategory </DialogTitle>
            <Formik
              enableReinitialize={true}
              initialValues={{
                subCategoryName: editData?.subCategoryName,
                categoryId: editData?.categoryId,
              }}
              //validationSchema={val_schema}
              onSubmit={handleSubCategoryUpdateSubmit}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <DialogContent sx={{ width: '600px' }}>
                    <DialogContentText></DialogContentText>
                    <Box></Box>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        CategoryName
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="CategoryName"
                        type="submit"
                        name="categoryId"
                        value={values.categoryId}
                        onChange={handleChange}
                      >
                        {props.category &&
                          props?.category.map(item => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.categoryName}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>

                    <Box sx={{ minWidth: 120, mt: 2 }}>
                      <TextField
                        margin="dense"
                        id="outlined-basic"
                        label="SubCategoryName"
                        type="text"
                        name="subCategoryName"
                        fullWidth
                        variant="outlined"
                        value={values.subCategoryName}
                        //onChange={(event) => {
                        //  setSubCategoryName(event.target.value);
                        //}}
                        onChange={handleChange}
                      ></TextField>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={loading}
                      endIcon={
                        loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : (
                          ''
                        )
                      }
                      // onClick={() => createNewSubCategory()}
                    >
                      Edit SubCategory
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </Dialog>

          {/* ========= Dialog for Delete subcategory ========= */}
          <Dialog
            open={deleteDialog}
            onClose={() => setDeleteDialog(false)}
            fullWidth
            maxWidth="sm"
          >
            <DialogContent>
              <DialogContentText sx={{ fontWeight: 'bold' }}>
                Do you want to delete this sub-category? -{' '}
                <span style={{ color: 'red' }}>
                  {subCatId?.subCategoryName}
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => deleteSubCategoryItem()}>Yes</Button>
              <Button onClick={() => setDeleteDialog(false)}>No</Button>
            </DialogActions>
          </Dialog>
          {/* <!--- Model Box ---> */}
          <div className="model_box">
            <Modal
              // show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Box>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Category Name
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  <form>
                    <div class="form-outline">
                      <input
                        type="text"
                        className="form-control"
                        id="outlined-basic"
                        //aria-describedby="emailHelp"
                        placeholder="Category Name"
                      />
                    </div>
                    <Button variant="secondary" onClick={handleClose}>
                      submit
                    </Button>
                    {/*<button type="submit" class="btn btn-success mt-4"></button>*/}
                  </form>
                </Typography>
              </Box>
            </Modal>

            {/* Model Box Finsihs */}
          </div>
          <Box
            sx={{
              width: '100%',
              // mt: '40px',
            }}
          >
            <MuiTable
              columns={columns}
              records={props?.subcategory || []}
              showCheckbox={false}
              keyField={'id'}
              onTableChange={handleTableChange}
              onRowsSelect={selectedRows => {}}
              searchQuery=""
              remote={true}
              paginationProps={{
                totalCount: pageable?.totalElements,
                rowsPerPage: pageable?.pageSize,
                page: pageable?.pageNo,
                orderBy: pageable?.sortBy,
                order: pageable?.sortDir,
                search: pageable?.search,
              }}
              showToolbar={true}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}></Paper>
      </Grid>
    </>
  )
}

const mapStateToProps = state => ({
  category: state.app.getCategories,
  subcategory: state.app.getSubCategories,
  pageable: state.app.pageableSubCategories,
})
const mapActionsToProps = {
  getCategoryAction,
  getAllSubCategory,
  getSubCategoryAction,
  postSubCategory,
  updateSubCategoryAction,
  deleteSubCategoryAction,
}

export default connect(mapStateToProps, mapActionsToProps)(SubCategoryList)
