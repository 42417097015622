// material-ui
import { Typography } from '@mui/material'

// project imports
import NavGroup from './nav-group'
import MenuItem from '../../../menu-items/MenuItem'
import { matchPath, useLocation } from 'react-router-dom'
import NavItem from './nav-item'

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { pathname } = useLocation()
  const match = path =>
    path ? !!matchPath({ path, end: false }, pathname) : false

  const navItems = MenuItem().items.map(item => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} active={match} />

      case 'item':
        return <NavItem key={item.id} item={item} level={1} active={match} />

      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        )
    }
  })

  return <>{navItems}</>
}

export default MenuList
