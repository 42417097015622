import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  PostAuthoriseAction,
  GetAccessTokenAction,
} from './../features/auth/redux/authAction'
/* 
executes outside of main SPA bundle!
should be a stateless volatile component
*/
function Callback({ ...props }) {
  const navigate = useNavigate()
  /* componentDidMount */

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const authCode = urlParams.get('code')
    const grant_type = 'authorization_code'
    const client_id = process.env.REACT_APP_AWS_COGNITO_CLIENTID
    const redirect_uri = process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI
    const client_secret = process.env.REACT_APP_AWS_COGNITO_CLIENTSECRET
    const urlencoded = new URLSearchParams()
    urlencoded.append('grant_type', grant_type)
    urlencoded.append('client_id', client_id)
    urlencoded.append('code', authCode)
    urlencoded.append('client_secret', client_secret)
    urlencoded.append('redirect_uri', redirect_uri)
    props.GetAccessTokenAction(urlencoded).then(() => {
      navigate('/')
    })
  }, [])

  return (
    <div>
      <h1></h1>
      <h3 style={{ marginLeft: '5vw' }}></h3>
    </div>
  )
}

const mapStateToProps = state => ({})
const mapActionsToProps = {
  PostAuthoriseAction,
  GetAccessTokenAction,
}

export default connect(mapStateToProps, mapActionsToProps)(Callback)
