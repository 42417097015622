// project imports

import AuthRoutes from './AuthRoutes'
import MainRoutes from './MainRoutes'

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes({ isLogged, userInfo }) {
  //return useRoutes([MainRoutes, AuthRoutes, ErrorRoutes]);
  return isLogged ? (
    <MainRoutes isLogged={isLogged} userInfo={userInfo} />
  ) : (
    <AuthRoutes isLogged={isLogged} />
  )
}
