import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortBy,
  defaultSortDir,
  defaultTotalPages,
  defaultTotalElements,
} from '../../../constants'
const initialState = {
  app: [],
  getCategories: [],
  getVendorData: [],
  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
  vendor: null,
  vendorList: null,
  subcategory: [],
  getSubCategories: [],
  getVendor: [],

  pageableSubCategories: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
  pageableVendor: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
}

export const appSlice = createSlice({
  name: 'appSlice',
  initialState: initialState,
  reducers: {
    getCategory: (state, action) => {
      state.app = action.payload.appResponse.content

      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.appResponse.pageNo,
        pageSize: action.payload.appResponse.pageSize,
        totalElements: action.payload.appResponse.totalElements,
        totalPages: action.payload.appResponse.totalPages,
      }
    },

    getVendorData: (state, action) => {
      state.getVendor = action.payload.appResponse.content

      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.appResponse.pageNo,
        pageSize: action.payload.appResponse.pageSize,
        totalElements: action.payload.appResponse.totalElements,
        totalPages: action.payload.appResponse.totalPages,
      }
    },

    getAllCategories: (state, action) => {
      state.getCategories = action.payload.appResponse
    },
    postCreateVendor: (state, action) => {
      state.vendor = action.payload
    },
    getVendorList: (state, action) => {
      state.vendorList = action.payload.appResponse
    },
    getsubCategory: (state, action) => {
      state.subcategory = action.payload.appResponse
    },
    getSubCategoryData: (state, action) => {
      state.getSubCategories = action.payload.appResponse.content
      state.pageableSubCategories = {
        ...state.pageable,
        pageNo: action.payload.appResponse.pageNo,
        pageSize: action.payload.appResponse.pageSize,
        totalElements: action.payload.appResponse.totalElements,
        totalPages: action.payload.appResponse.totalPages,
      }
    },

    getVendorData: (state, action) => {
      state.getVendor = action.payload.appResponse.content
      state.pageableVendor = {
        ...state.pageable,
        pageNo: action.payload.appResponse.pageNo,
        pageSize: action.payload.appResponse.pageSize,
        totalElements: action.payload.appResponse.totalElements,
        totalPages: action.payload.appResponse.totalPages,
      }
    },
  },
})

export default appSlice
