import React, { useEffect, useState, useRef } from 'react'
import { CardContent, Typography, Grid, Button, TextField } from '@mui/material'
import MainCard from '../../../components/cards/MainCard'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import DownloadIcon from '@mui/icons-material/Download'
import UploadIcon from '@mui/icons-material/Upload'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from '@mui/styles'
import {
  secondsToTimeCode,
  formatSecondsToTimecode,
} from '../../../utils/helperFunctions'
import CreatableSelect from 'react-select/creatable'

import instance, { instanceVideoFile } from '../../../middleware/axiosInstance'
import { toast } from 'react-toastify'
import { Box } from '@mui/system'

const useStyles = makeStyles(theme => ({
  mainTimeCode: {
    backgroundColor: '#eeeeee',
    height: 45,
    fontSize: 18,
  },
  arrowButton: {
    height: 45,
  },
  button: {
    height: 30,
    width: 120,
  },
  clipsTimecode: {
    backgroundColor: '#eeeeee',
    margin: '15px',
    fontSize: 16,
  },
  selectBox: {
    height: 30,
  },
  hideSeekBar: {
    cursor: 'pointer',
    '&::-webkit-media-controls-timeline': {
      display: 'none !important',
    },
    '&::-webkit-media-controls-time-remaining-display': {
      display: 'none !important',
    },
  },
}))

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: '#fff',
    borderColor: '#ddd',
    minHeight: '40px',
    boxShadow: state.isFocused ? null : null,
  }),
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="white"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const MyAssetBulkPage = () => {
  const [platform, setPlatform] = useState('')
  const [videoAssets, setVideoAssets] = useState([])
  const [trimmedVideoAssets, setTrimmedVideoAssets] = useState([])
  const [currentFrame, setCurrentFrame] = useState('00')
  const [currentTimecode, setCurrentTimecode] = useState('00:00:00:00')
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)
  const [trimVideoCount, setTrimVideoCount] = useState(0)
  const [ffmpegResult, setFfmpegResult] = useState(null)
  const [batchValue, setBatchValue] = useState('')
  const [progress, setProgress] = React.useState(0)
  const [loading, setLoading] = useState(false)

  //useEffect(() => {}, [videoAssets])
  const updatedVideoAssets = []
  const updatedTrimmedVideoAssets = []
  const videoRef = useRef([])
  const trimmedVideoRef = useRef([])
  const ffmpegRef = useRef()
  const videoClipNameRef = useRef([])
  const tagsRef = useRef([])
  const timeCodeRef = useRef([])
  const timeCodeBackwardRef = useRef([])
  const timeCodeForwardRef = useRef([])
  const trimStartRef = useRef([])
  const trimEndRef = useRef([])
  const trimStartTimeRef = useRef([])
  const trimEndTimeRef = useRef([])
  const trimVideo = useRef([])
  const scriptToTrim = useRef([])
  const approveRef = useRef([])
  const uploadVideo = useRef([])
  const videoFiles = useRef([])
  const trimmedVideoFiles = useRef([])
  const framesPerSecond = 30

  const classes = useStyles()

  useEffect(() => {
    const detectPlatform = () => {
      const isWindows = navigator.platform.indexOf('Win') > -1
      const isMac = navigator.platform.indexOf('Mac') > -1

      if (isWindows) {
        setPlatform('Windows')
      } else if (isMac) {
        setPlatform('macOS')
      } else {
        setPlatform('Unknown')
      }
    }

    detectPlatform()
  }, [])
  //Created to load script by passing the required script and append in head tag
  const loadScript = src => {
    return new Promise((onFulfilled, _) => {
      const script = document.createElement('script')
      let loaded
      script.async = 'async'
      script.crossorigin = true
      script.defer = 'defer'
      script.setAttribute('src', src)
      script.onreadystatechange = script.onload = () => {
        if (!loaded) {
          onFulfilled(script)
        }
        loaded = true
      }
      script.onerror = function () {
        console.log('Script failed to load')
      }
      document.getElementsByTagName('head')[0].appendChild(script)
    })
  }

  useEffect(() => {
    //Load the ffmpeg script
    loadScript(
      'https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.11.2/dist/ffmpeg.min.js'
    ).then(() => {
      if (typeof window !== 'undefined') {
        // creates a ffmpeg instance.
        ffmpegRef.current = window.FFmpeg.createFFmpeg({ log: true })
        //Load ffmpeg.wasm-core script
        ffmpegRef.current.load()

        // if (!isScriptLoaded) {
        //   console.oldLog = console.log

        //   console.log = function (value) {
        //     if (typeof value == 'string' && value.indexOf('[fferr]') >= 0) {
        //       setFfmpegResult(ffmpegResult + '\n' + value)
        //       localStorage.setItem(
        //         'ffmpegTrimResult',
        //         value + '\n' + localStorage.getItem('ffmpegTrimResult')
        //       )
        //     } else {
        //       console.oldLog(value) // Call the original console.log function
        //       window.$log = value
        //     }
        //   }
        // }

        //Set true that the script is loaded
        setIsScriptLoaded(true)
      }
    })
  }, [videoAssets])

  // Create refs for each element in the array
  videoRef.current = videoAssets.map(
    (item, index) => videoRef.current[index] ?? React.createRef()
  )
  trimmedVideoRef.current = videoAssets.map(
    (item, index) => trimmedVideoRef.current[index] ?? React.createRef()
  )
  timeCodeRef.current = videoAssets.map(
    (item, index) => timeCodeRef.current[index] ?? React.createRef()
  )
  timeCodeBackwardRef.current = videoAssets.map(
    (item, index) => timeCodeBackwardRef.current[index] ?? React.createRef()
  )
  timeCodeForwardRef.current = videoAssets.map(
    (item, index) => timeCodeForwardRef.current[index] ?? React.createRef()
  )
  trimStartRef.current = videoAssets.map(
    (item, index) => trimStartRef.current[index] ?? React.createRef()
  )
  trimEndRef.current = videoAssets.map(
    (item, index) => trimEndRef.current[index] ?? React.createRef()
  )
  trimStartTimeRef.current = videoAssets.map(
    (item, index) => trimStartTimeRef.current[index] ?? React.createRef()
  )
  trimEndTimeRef.current = videoAssets.map(
    (item, index) => trimEndTimeRef.current[index] ?? React.createRef()
  )
  tagsRef.current = videoAssets.map(
    (item, index) => tagsRef.current[index] ?? React.createRef()
  )
  videoClipNameRef.current = videoAssets.map(
    (item, index) => videoClipNameRef.current[index] ?? React.createRef()
  )
  trimVideo.current = videoAssets.map(
    (item, index) => trimVideo.current[index] ?? React.createRef()
  )
  scriptToTrim.current = videoAssets.map(
    (item, index) => scriptToTrim.current[index] ?? React.createRef()
  )
  approveRef.current = videoAssets.map(
    (item, index) => approveRef.current[index] ?? React.createRef()
  )
  uploadVideo.current = videoAssets.map(
    (item, index) => uploadVideo.current[index] ?? React.createRef()
  )

  useEffect(() => {
    videoAssets.map((item, index) => {
      trimVideo.current[index].current.disabled = true
      trimVideo.current[index].current.style.opacity = '0.5'
      scriptToTrim.current[index].current.disabled = true
      scriptToTrim.current[index].current.style.opacity = '0.5'
      uploadVideo.current[index].current.disabled = true
      uploadVideo.current[index].current.style.opacity = '0.5'
    })
    // videoFiles.current = videoAssets.map(
    //   (item, index) => videoFiles.current[index] ?? React.createRef()
    // )
  }, [videoAssets])

  function handleForward(index) {
    const currentFrame = Math.round(
      (videoRef.current[index].current.currentTime += 1 / framesPerSecond)
    )
    const padZero = number => {
      return number.toString().padStart(2, '0')
    }
    setCurrentFrame(`${padZero(currentFrame)}`)
  }

  function handleBackward(index) {
    const currentFrame = Math.round(
      (videoRef.current[index].current.currentTime -= 1 / framesPerSecond)
    )
    const padZero = number => {
      return number.toString().padStart(2, '0')
    }
    setCurrentFrame(`${padZero(currentFrame)}`)
  }

  function handleClipStart(index) {
    //testFFProbe(index)
    const currentTime = videoRef.current[index].current.currentTime
    //setStartClipTimeCode(secondsToTimeCode(currentTime))
    trimStartRef.current[index].current.value = secondsToTimeCode(currentTime)
    trimStartTimeRef.current[index].current.value = currentTime
  }

  function handleClipEnd(index) {
    const currentTime = videoRef.current[index].current.currentTime
    //setEndClipTimeCode(secondsToTimeCode(currentTime))
    trimEndRef.current[index].current.value = secondsToTimeCode(currentTime)
    trimEndTimeRef.current[index].current.value = currentTime
    trimVideo.current[index].current.disabled = false
    trimVideo.current[index].current.style.opacity = '1'
    videoRef.current[index].current.pause()
    // loadScript(
    //   'https://cdn.jsdelivr.net/npm/@ffmpeg/ffmpeg@0.11.2/dist/ffmpeg.min.js'
    // ).then(() => {
    //   if (typeof window !== 'undefined') {
    //     // creates a ffmpeg instance.
    //     ffmpegRef.current = window.FFmpeg.createFFmpeg({ log: true })
    //     //Load ffmpeg.wasm-core script
    //     ffmpegRef.current.load()
    //     //Set true that the script is loaded
    //     setIsScriptLoaded(true)
    //   }
    // })
  }

  function handleTimeUpdate(index) {
    const currentTime = videoRef.current[index].current.currentTime
    console.log(currentTime)
    //setCurrentTimecode(secondsToTimeCode(currentTime))
    timeCodeRef.current[index].current.innerHTML =
      secondsToTimeCode(currentTime)
  }

  //Convert the time obtained from the video to HH:MM:SS format
  const convertToHHMMSS = val => {
    const secNum = parseInt(val, 10)
    let hours = Math.floor(secNum / 3600)
    let minutes = Math.floor((secNum - hours * 3600) / 60)
    let seconds = secNum - hours * 3600 - minutes * 60

    if (hours < 10) {
      hours = '0' + hours
    }
    if (minutes < 10) {
      minutes = '0' + minutes
    }
    if (seconds < 10) {
      seconds = '0' + seconds
    }
    let time
    // only mm:ss
    if (hours === '00') {
      time = minutes + ':' + seconds
    } else {
      time = hours + ':' + minutes + ':' + seconds
    }
    return time
  }

  const handlePreviewTrimmedVideo = index => {
    let validationMessage = []
    if (
      parseFloat(trimEndTimeRef.current[index].current.value) <
      parseFloat(trimStartTimeRef.current[index].current.value)
    ) {
      validationMessage.push('End time must be greater than start time!')
    }

    const videoName =
      videoClipNameRef.current[index].current?.lastChild?.firstChild?.value
    //videoFiles.current[index].current.name
    const tags = tagsRef.current[index].current
      .getValue()
      .map(tag => tag.value)
      .join(',')

    if (videoName == null || videoName.length <= 0) {
      validationMessage.push('Specify some name to this clip')
    }
    if (tags == null || tags.length <= 0) {
      validationMessage.push('Add some tags to this clip.')
    }
    if (validationMessage.length == 0) {
      approveRef.current[index].current.value = ''
      trimmedVideoRef.current[index].current.src = videoAssets[index].blogUrl
      trimmedVideoRef.current[index].current.currentTime =
        trimStartTimeRef.current[index].current.value

      scriptToTrim.current[index].current.disabled = false
      scriptToTrim.current[index].current.style.opacity = '1'
      trimmedVideoRef.current[index].current.addEventListener(
        'timeupdate',
        function () {
          if (this.currentTime >= trimEndTimeRef.current[index].current.value) {
            this.pause()
            setTimeout(() => {
              this.currentTime = trimStartTimeRef.current[index].current.value
            }, 2000)
          }
        }
      )
    } else {
      scriptToTrim.current[index].current.disabled = true
      scriptToTrim.current[index].current.style.opacity = '0.5'
      //validationMessage += '<ol>' + validationMessage + '</ol>'

      const CustomToast = ({ closeToast }) => (
        <div>
          <h3>Check below errors</h3>

          {/* This is a custom toast with <strong>HTML content</strong>.<br /> */}
          <ul>
            {validationMessage.map((item, index) => {
              return <li>{item}</li>
            })}
          </ul>
        </div>
      )
      toast.error(<CustomToast />, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      })
    }
  }

  const handleApproveAndScriptToTrim = index => {
    let videoFileValue = videoFiles.current[index].current.file
    const { name, type } = videoFileValue
    let ffmpegStringCommand = ''
    if (platform == 'Windows') {
      ffmpegStringCommand +=
        'for /f "delims=" %%A in (\'ffprobe -v error -select_streams v:0 -show_entries stream^=bit_rate -of default^=noprint_wrappers^=1:nokey^=1 -sexagesimal ' +
        name +
        '\') do set "bitrate=%%A"~'
      ffmpegStringCommand += 'ffmpeg -hide_banner -loglevel info -i '
      ffmpegStringCommand += name
      ffmpegStringCommand +=
        ' -ss ' +
        formatSecondsToTimecode(trimStartTimeRef.current[index].current.value) +
        ' -to ' +
        formatSecondsToTimecode(trimEndTimeRef.current[index].current.value) +
        ' -c:v libx264 ' +
        ' -b:v ' +
        '%bitrate% ' +
        '-c:a aac -movflags faststart '
      ffmpegStringCommand += 'output/Trimmed-' + name
      //ffmpegStringCommand += 'command2 %output%'
    } else {
      ffmpegStringCommand +=
        'bitrate=$(ffprobe -v error -select_streams v:0 -show_entries stream=bit_rate -of default=noprint_wrappers=1:nokey=1 -sexagesimal ' +
        name +
        ')~'
      //ffmpeg -i SJ-Explains-Kafka.mp4 -ss 00:00:00 -to 00:02:18 -c:v libx264 -b:v 4395909 -c:a aac -async 1 output/SJ-Explains-Kafka-6.mp4
      ffmpegStringCommand += 'ffmpeg -hide_banner -loglevel info -i '
      ffmpegStringCommand += name
      ffmpegStringCommand +=
        ' -ss ' +
        formatSecondsToTimecode(trimStartTimeRef.current[index].current.value) +
        ' -to ' +
        formatSecondsToTimecode(trimEndTimeRef.current[index].current.value) +
        ' -c:v libx264 ' +
        ' -b:v ' +
        '"$bitrate" ' +
        '-c:a aac -movflags faststart '
      ffmpegStringCommand += 'output/Trimmed-' + name
      //ffmpegStringCommand += '\n'
    }
    console.log(ffmpegStringCommand)

    approveRef.current[index].current.value = ffmpegStringCommand
    //setScriptLines(prevLines => [...prevLines, ffmpegStringCommand])
    updateTrimVideoCount()
    uploadVideo.current[index].current.disabled = false
    uploadVideo.current[index].current.style.opacity = '1'
  }

  const updateTrimVideoCount = () => {
    let cnt = 0
    approveRef.current.map((item, index) => {
      if (item.current.value != null && item.current.value != '') {
        cnt++
      }
    })
    setTrimVideoCount(cnt)
  }

  const handleDownloadScript = () => {
    let ffmpegStringCommand =
      '' + (platform == 'Windows' ? '@echo off\n' : '') + ''
    approveRef.current.map((item, index) => {
      if (item.current.value && item.current.value.length > 0)
        ffmpegStringCommand += item.current.value.replace('~', '\n') + '\n'
    })
    console.log(ffmpegStringCommand)
    const element = document.createElement('a')
    const file = new Blob([ffmpegStringCommand], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = 'bulk-trim.' + (platform == 'Windows' ? 'bat' : 'sh')
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  const handleUploadVideos = index => {
    //Iterate VideoAssets Ref Array
    let videoFileValue = videoFiles.current[index].current.file
    const { name, type } = videoFileValue
    const videoName =
      videoClipNameRef.current[index].current?.lastChild?.firstChild?.value
    //videoFiles.current[index].current.name
    const tags = tagsRef.current[index].current.getValue().map(tag => tag.value)

    let fileName = 'Trimmed-' + name
    let newFileIndex = -1

    trimmedVideoFiles.current.map((item, trimIndex) => {
      if (item.current.name == fileName) {
        newFileIndex = trimIndex
      }
    })
    //Hit Save Private Meta Data
    if (newFileIndex >= 0) {
      setLoading(true)
      uploadVideo.current[index].current.disabled = true
      uploadVideo.current[index].current.style.opacity = '0.5'
      let dotIndex = 0
      let uploadInterval = setInterval(() => {
        let dotValue = ''
        for (let i = 0; i <= dotIndex; i++) {
          dotValue += '. '
        }
        uploadVideo.current[index].current.innerHTML = dotValue
        if (dotIndex < 2) dotIndex++
        else dotIndex = 0
      }, 500)
      let ret = instance
        .post(`${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip`, {
          name: videoName,
          batch: batchValue,
          tags: tags,
          categories: null,
        })
        .then(response => {
          const url = `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip/upload?id=${response.data.id}&fileName=${name}`
          var config = {
            method: 'put',
            url: url,
          }
          instance(config)
            .then(resp => {
              if (resp?.status === 200) {
                const success = resp?.data
                toast.warning(success, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
                })
                // setSuccess(true)
                // setLoading(false)
                // setProgress(0)
              }

              const videoUrl = resp?.data?.uploadUrl

              uploadVideoFileToS3(
                videoUrl,
                trimmedVideoFiles.current[newFileIndex].current,
                uploadInterval,
                index
              )
              setTimeout(() => {
                // uploadVideo.current[index].current.innerHTML = 'Upload'
                // clearInterval(uploadInterval)
              }, 5000)
            })
            .catch(err => {
              toast.error('Something went wrong!', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 2000,
              })
              setLoading(false)
            })
        })
    } else {
      toast.error('Select Output Folder', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      })
    }
  }

  //   /* =========Api implementation for video upload ========= */
  const uploadVideoFileToS3 = (videoUrl, file, uploadInterval, index) => {
    const type = file?.type

    return new Promise((resolve, reject) => {
      instanceVideoFile
        .put(videoUrl, file, {
          headers: {
            'Content-Type': type,
          },
          onUploadProgress: file => {
            //Set the progress value to show the progress bar
            console.log(
              'Progress :: ' + Math.round((100 * file.loaded) / file.total)
            )
            setProgress(Math.round((100 * file.loaded) / file.total))
          },
        })
        .then(response => {
          if (response.status === 200) {
            toast.success('Successfully Uploaded Video!', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            })
            setProgress(0)
            setLoading(false)
            uploadVideo.current[index].current.innerHTML = 'Upload'
            clearInterval(uploadInterval)
            // navigate('/assets/list', { state: { file } })
          } else {
            toast.error('Something went wrong', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            })
            uploadVideo.current[index].current.innerHTML = 'Upload'
            clearInterval(uploadInterval)
          }
        })
    })
  }

  const getFPSByVideoIndex = async index => {
    let fps = 30
    try {
      //handleTrimVideo(index)
      let videoFileValue = videoFiles.current[index].current.file

      let ffmpeg = ffmpegRef.current

      if (isScriptLoaded) {
        const { name, type } = videoFileValue
        //Write video to memory
        ffmpeg.FS(
          'writeFile',
          name,
          await window.FFmpeg.fetchFile(videoFileValue)
        )

        try {
          await ffmpeg.run('-i', name)
        } catch (error) {
          console.error('Error occurred during FFmpeg run:', error)
        }

        setInterval(() => {
          const output = localStorage.getItem('ffmpegTrimResult')
          if (output != null) {
            const lines = output.split('\n')
            //Look for the line containing the FPS value
            const fpsLine = lines.find(line => line.includes('fps'))
            fpsLine.split(',').map(item => {
              if (item.indexOf('fps') >= 0) {
                console.log(`FPS is : ${item}`)
              }
            })
          }
        }, 1000)
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  const getFPSByVideoFile = async videoFileValue => {
    let fps = 30
    try {
      let ffmpeg = ffmpegRef.current

      if (isScriptLoaded) {
        const { name, type } = videoFileValue
        //Write video to memory
        ffmpeg.FS(
          'writeFile',
          name,
          await window.FFmpeg.fetchFile(videoFileValue)
        )

        try {
          await ffmpeg.run('-i', name)
        } catch (error) {
          console.error('Error occurred during FFmpeg run:', error)
        }

        console.log(name)
        let intervalCounter = 0
        let intervalHandle = setInterval(() => {
          const output = localStorage.getItem('ffmpegTrimResult')
          if (output != null) {
            const lines = output.split('\n')
            //Look for the line containing the FPS value
            const fpsLine = lines.find(line => line.includes('fps'))
            fpsLine.split(',').map(item => {
              if (item.indexOf('fps') >= 0) {
                console.log(`FPS is : ${item}`)
              }
            })
          }
          intervalCounter++
          if (intervalCounter > 3) clearInterval(intervalHandle)
        }, 1000)
      }
    } catch (ex) {
      console.log(ex)
    }
  }

  const handleTrimVideo = async index => {
    let videoFileValue = videoFiles.current[index].current.file
    let startTime = 1
    let endTime = 3

    let ffmpeg = ffmpegRef.current

    if (isScriptLoaded) {
      const { name, type } = videoFileValue
      //Write video to memory
      ffmpeg.FS(
        'writeFile',
        name,
        await window.FFmpeg.fetchFile(videoFileValue)
      )
      const videoFileType = type.split('/')[1]
      //Run the ffmpeg command to trim video
      // await ffmpeg.run(
      //   '-i',
      //   name,
      //   '-ss',
      //   `${convertToHHMMSS(startTime)}`,
      //   '-to',
      //   `${convertToHHMMSS(endTime)}`,
      //   '-acodec',
      //   'copy',
      //   '-vcodec',
      //   'copy',
      //   `out_${index}.${videoFileType}`
      // )
      // await ffmpeg.run(
      //   '-i',
      //   name,
      //   '-ss',
      //   `${convertToHHMMSS(startTime)}`,
      //   '-to',
      //   `${convertToHHMMSS(endTime)}`,
      //   '-c:v',
      //   'libx264',
      //   '-c:a',
      //   'aac',
      //   '-movflags',
      //   'faststart',
      //   // '-s',
      //   // 'ASSERTIONS=1',
      //   `out_${index}.${videoFileType}`
      // )
      console.log(
        'ffmpeg ' +
          '-i ' +
          name +
          ' -ss ' +
          `${convertToHHMMSS(startTime)}` +
          ' StartTime ' +
          startTime +
          ' -to ' +
          `${convertToHHMMSS(endTime)}` +
          ' EndTime ' +
          endTime +
          ' -acodec ' +
          ' copy ' +
          ' -vcodec ' +
          ' copy ' +
          `out_${index}.${videoFileType}`
      )
      //ffmpeg -i 1_1.mp4 -ss 00:09 StartTime 9 -to 00:12 EndTime 12 -acodec  copy  -vcodec  copy  out.mp4

      //Convert data to url and store in videoTrimmedUrl state

      const data = ffmpeg.FS('readFile', `out_${index}.${videoFileType}`)
      const url = URL.createObjectURL(
        new Blob([data.buffer], { type: videoFileValue.type })
      )
      console.log(url)
      videoAssets[index].trimmedUrl = url
      trimmedVideoRef.current[index].current.src = url
      // setTimeout(() => {
      //   hanldeText()
      //   uploadVideoToS3(
      //     'https://whilter-dev-template-zip.s3.ap-south-1.amazonaws.com/divya%40racloop.com/26-05-2023/5fa57288-ea37-4fa1-8868-ddff2b2383c3.zip?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20230526T142148Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Credential=AKIATZUNEARHA62YGRZG%2F20230526%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=85b8cacd42fd134a7fac49ef03eef20296c189e73a30c6cd2045eaea00876c93',
      //     new Blob([data.buffer])
      //   )
      // }, 1000)
    }
  }

  const handleFolderSelect = event => {
    var output = document.querySelector('ol.fileList')
    var files = event.target.files

    //let videoAsset = {}
    let fileIndex = 0
    for (var i = 0; i < files.length; i++) {
      if (
        files[i].webkitRelativePath.toString().toLowerCase().indexOf('.mp4') > 0
      ) {
        //Reset object at each iteration
        // videoAsset = new Object()
        videoFiles.current[fileIndex] = React.createRef()
        videoFiles.current[fileIndex].current = {
          name: 'Video-' + (fileIndex + 1).toString(),
          file: files[i],
        }
        var item = document.createElement('li')
        var vPlayer = document.createElement('video')
        let blobURL = URL.createObjectURL(files[i])
        vPlayer.src = blobURL
        vPlayer.width = '300'
        vPlayer.controls = true
        item.innerHTML = '<span>' + files[i].webkitRelativePath + '</span><br>'
        item.appendChild(vPlayer)
        //output.appendChild(item)

        //Set values of each file object coming in iteration
        const videoAssetObj = {
          index: fileIndex + 1,
          name: 'Video-' + (fileIndex + 1).toString(),
          path: files[i].webkitRelativePath,
          file: files[i],
          blogUrl: URL.createObjectURL(files[i]),
        }

        //getFPSByVideoFile(files[i])

        //Push object to Array
        updatedVideoAssets.push(videoAssetObj)
        fileIndex++
      }
    }
    setVideoAssets([...updatedVideoAssets])
    //if (scriptLines.length == 0) setScriptLines(['echo off\n'])
  }

  const handleOutputFolderSelect = event => {
    let files = event.target.files

    //let videoAsset = {}
    let fileIndex = 0
    for (var i = 0; i < files.length; i++) {
      if (
        files[i].webkitRelativePath.toString().toLowerCase().indexOf('.mp4') > 0
      ) {
        //Reset object at each iteration
        // videoAsset = new Object()
        trimmedVideoFiles.current[fileIndex] = React.createRef()
        trimmedVideoFiles.current[fileIndex].current = files[i]
        //Set values of each file object coming in iteration
        const trimmedVideoAssetObj = {
          index: fileIndex + 1,
          name: 'Video-' + (fileIndex + 1).toString(),
          path: files[i].webkitRelativePath,
          file: files[i],
          blogUrl: URL.createObjectURL(files[i]),
        }

        //Push object to Array
        updatedTrimmedVideoAssets.push(trimmedVideoAssetObj)
        fileIndex++
      }
    }
    setTrimmedVideoAssets([...updatedTrimmedVideoAssets])
  }

  useEffect(() => {
    //Update list of Video Assets in Local Storage
    //localStorage.setItem('listVideoAssets', JSON.stringify(videoAssets))
  }, [videoAssets])

  return (
    <>
      <MainCard
        title={<Typography variant="h3">Bulk My Asset Upload</Typography>}
        sx={{ overflow: 'auto' }}
      >
        <CardContent sx={{ width: '1378px', overflow: 'none' }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Grid
              direction="row"
              sx={{ width: '600px', display: 'flex', flexDirection: 'row' }}
            >
              <Grid sx={{ width: '300px' }}>
                {/* <span
                style={{
                  lineHeight: '30px',
                  width: '390px',
                  display: 'inline-block',
                  verticalAlign: 'bottom',
                }}
              >
                Batch
              </span> */}
                <Typography variant="h4" sx={{ mb: 1 }}>
                  Batch
                </Typography>

                <TextField
                  fullWidth
                  placeholder="Mention batch, e.g. date/subject/model"
                  value={batchValue}
                  onChange={event => {
                    setBatchValue(event.target.value)
                  }}
                />
              </Grid>
              <Box
                sx={{ height: '0px', paddingTop: '27px', paddingLeft: '10px' }}
              >
                {/* <Typography variant="h4" sx={{ mb: 1 }}>
                  Select Output Folder
                </Typography> */}
                <input type="hidden" value={ffmpegResult}></input>
                <Box
                  sx={{
                    position: 'relative',
                    top: '0',
                    left: '0',
                    width: '200px',
                  }}
                >
                  <Box>
                    <Button
                      sx={{
                        backgroundColor: '#efefef',
                        border: 'dashed 1px #cdcdcd',
                        width: '200px',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '0',
                        cursor: 'pointer',
                      }}
                      disabled={batchValue?.length > 0 ? false : true}
                    >
                      <UploadIcon sx={{ marginRight: '5px' }} />
                      Upload Batch
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: '#efefef',
                      border: 'dashed 1px #cdcdcd',
                      width: '200px',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      opacity: '0',
                      zIndex: '10',
                      cursor: 'pointer',
                    }}
                  >
                    <input
                      id="inputFolder"
                      type="file"
                      onChange={handleFolderSelect}
                      style={{ cursor: 'pointer', height: '40px' }}
                      directory=""
                      webkitdirectory=""
                      disabled={batchValue?.length > 0 ? false : true}
                    ></input>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid>
              <Grid
                sx={{
                  width: '420px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    top: '15px',
                    mr: '10px',
                  }}
                >
                  <Button
                    sx={{
                      backgroundColor: '#efefef',
                      border: 'dashed 1px #cdcdcd',
                      width: '210px',
                    }}
                    disabled={trimVideoCount > 0 ? false : true}
                    onClick={handleDownloadScript}
                  >
                    <DownloadIcon sx={{ marginRight: '5px' }} />
                    Download Script ({trimVideoCount})
                  </Button>
                </Box>

                <Grid>
                  <Box sx={{ height: '14px' }}>
                    <Box
                      sx={{
                        position: 'relative',
                        top: '0',
                        left: '0',
                        width: '200px',
                      }}
                    >
                      <Box>
                        <Button
                          sx={{
                            backgroundColor: '#efefef',
                            border: 'dashed 1px #cdcdcd',
                            width: '200px',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            zIndex: '0',
                            cursor: 'pointer',
                          }}
                          disabled={trimVideoCount > 0 ? false : true}
                        >
                          <UploadIcon sx={{ marginRight: '5px' }} />
                          Select Output Folder
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: '#efefef',
                          border: 'dashed 1px #cdcdcd',
                          width: '200px',
                          position: 'absolute',
                          top: '0',
                          left: '0',
                          opacity: '0',
                          zIndex: '10',
                          cursor: 'pointer',
                        }}
                      >
                        <input
                          id="outputFolder"
                          type="file"
                          onChange={handleOutputFolderSelect}
                          style={{ cursor: 'pointer', height: '40px' }}
                          directory=""
                          disabled={trimVideoCount > 0 ? false : true}
                          webkitdirectory=""
                        ></input>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: '#efefef',
              height: '50px',
              lineHeight: '50px',
              verticalAlign: 'bottom',
              padding: '0px 10px',
              marginBottom: '10px',
            }}
          >
            <Grid sx={{ width: '30%', textAlign: 'center' }}>
              <Typography
                variant="h3"
                sx={{
                  mb: 1,
                  textAlign: 'center',
                  padding: '0px',
                  margin: '0px',
                }}
              >
                Original Video
              </Typography>
            </Grid>
            <Grid sx={{ width: '24%', textAlign: 'center' }}>
              <Typography
                variant="h3"
                sx={{
                  mb: 1,
                  textAlign: 'center',
                  padding: '0px',
                  margin: '0px',
                }}
              >
                Trimming Inputs
              </Typography>
            </Grid>
            <Grid sx={{ width: '30%', textAlign: 'center' }}>
              <Typography
                variant="h3"
                sx={{
                  mb: 1,
                  textAlign: 'center',
                  padding: '0px',
                  margin: '0px',
                }}
              >
                Trimmed Video Preview
              </Typography>
            </Grid>
            <Grid sx={{ width: '16%', textAlign: 'center' }}>
              <Typography
                variant="h3"
                sx={{
                  mb: 1,
                  textAlign: 'center',
                  padding: '0px',
                  margin: '0px',
                }}
              >
                Approve & Upload
              </Typography>
            </Grid>
          </Grid>

          {videoAssets.map((video, index) => (
            <>
              <Grid
                container
                direction="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{
                  padding: '0px 10px',
                }}
              >
                <Grid sx={{ width: '30%', textAlign: 'center' }}>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 1,
                      textAlign: 'center',
                      padding: '0px',
                      marginTop: '4px',
                      marginBottom: '8px',
                    }}
                  >
                    {video.path}
                  </Typography>
                  <video
                    id={index}
                    key={index}
                    controls
                    width="400"
                    height="auto"
                    src={video?.blogUrl}
                    ref={videoRef.current[index]}
                    onTimeUpdate={() => {
                      handleTimeUpdate(index)
                    }}
                  ></video>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '10px',
                      height: '30px',
                      width: '400px',
                      alignItems: 'center',
                      justifyContent: 'right',
                      margin: '0px auto 20px auto',
                    }}
                  >
                    <Button
                      id={index}
                      key={index}
                      variant="outlined"
                      className={classes.mainTimeCode}
                      ref={timeCodeRef.current[index]}
                      sx={{
                        color: '#999',
                        borderColor: '#aaaaaa',
                        width: '100%',
                        fontSize: '30px',
                      }}
                    >
                      {currentTimecode}
                    </Button>
                    <Button
                      onClick={() => {
                        handleBackward(index)
                      }}
                      variant="outlined"
                      className={classes.arrowButton}
                      ref={timeCodeBackwardRef.current[index]}
                      sx={{
                        marginLeft: '10px',
                      }}
                    >
                      <ArrowBackIosNewIcon />
                    </Button>
                    <Button
                      onClick={() => {
                        handleForward(index)
                      }}
                      variant="outlined"
                      className={classes.arrowButton}
                      ref={timeCodeForwardRef.current[index]}
                      sx={{
                        marginLeft: '1px',
                      }}
                    >
                      <ArrowForwardIosIcon />
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  sx={{
                    width: '24%',
                    textAlign: 'left',
                    padding: '0px 20px',
                  }}
                >
                  <span
                    style={{
                      lineHeight: '30px',
                      display: 'inline-block',
                      verticalAlign: 'bottom',
                    }}
                  >
                    Name
                  </span>
                  <br />
                  <TextField
                    fullWidth
                    placeholder="Add some title for clip"
                    ref={videoClipNameRef.current[index]}
                    // value={video.name}
                    onChange={event => {}}
                  />
                  <span
                    style={{
                      lineHeight: '30px',
                      display: 'inline-block',
                      verticalAlign: 'bottom',
                    }}
                  >
                    Tags
                  </span>
                  <br />
                  <CreatableSelect
                    isMulti
                    ref={tagsRef.current[index]}
                    styles={customStyles}
                    components={{
                      DropdownIndicator: () => null,
                      IndicatorSeparator: () => null,
                    }}
                    noOptionsMessage={() => 'type tag and hit create'}
                    placeholder="Add some tags for clip"
                  />
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '10px',
                    }}
                  >
                    <Button
                      sx={{
                        width: '150px',
                        marginRight: '10px',
                        backgroundColor: '#efefef',
                      }}
                      onClick={() => {
                        handleClipStart(index)
                      }}
                    >
                      Trim Start
                    </Button>
                    <TextField
                      placeholder="00:00:00:00"
                      sx={{ width: '100%' }}
                      disabled
                      inputRef={trimStartRef.current[index]}
                    />
                    <input
                      type="hidden"
                      ref={trimStartTimeRef.current[index]}
                    />
                  </Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      paddingTop: '10px',
                    }}
                  >
                    <Button
                      sx={{
                        width: '150px',
                        marginRight: '10px',
                        backgroundColor: '#efefef',
                      }}
                      onClick={() => {
                        handleClipEnd(index)
                      }}
                    >
                      Trim End
                    </Button>
                    <TextField
                      placeholder="00:00:00:00"
                      sx={{ width: '100%' }}
                      inputRef={trimEndRef.current[index]}
                      disabled
                    />
                    <input type="hidden" ref={trimEndTimeRef.current[index]} />
                  </Grid>
                  <Grid
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      padding: '10px 0px',
                      alignContent: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      sx={{
                        float: 'right',
                        backgroundColor: '#efefef',
                        border: 'dashed 1px #cdcdcd',
                        width: '100%',
                        height: '45px',
                        marginTop: '3px',
                      }}
                      ref={trimVideo.current[index]}
                      onClick={() => {
                        handlePreviewTrimmedVideo(index)
                      }}
                    >
                      Preview Trimmed Video
                    </Button>
                  </Grid>
                </Grid>
                <Grid sx={{ width: '30%', textAlign: 'center' }}>
                  <Typography
                    variant="h5"
                    sx={{
                      mb: 1,
                      textAlign: 'center',
                      padding: '0px',
                      marginTop: '4px',
                      marginBottom: '8px',
                    }}
                  >
                    {video.path}
                  </Typography>
                  <video
                    controls
                    width="400"
                    height="auto"
                    className={classes.hideSeekBar}
                    src={video?.trimmedUrl}
                    ref={trimmedVideoRef.current[index]}
                  ></video>
                </Grid>
                <Grid
                  sx={{
                    width: '16%',
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '30px',
                    flexDirection: 'column',
                  }}
                >
                  <Grid
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor: '#efefef',
                        border: 'dashed 1px #cdcdcd',
                        marginLeft: '10px',
                        marginRight: '10px',
                        width: '100px',
                      }}
                      ref={scriptToTrim.current[index]}
                      onClick={() => {
                        handleApproveAndScriptToTrim(index)
                      }}
                    >
                      Approve
                    </Button>
                    <input type="hidden" ref={approveRef.current[index]} />
                    <Button
                      sx={{
                        backgroundColor: '#efefef',
                        border: 'dashed 1px #cdcdcd',
                        width: '80px',
                      }}
                      ref={uploadVideo.current[index]}
                      onClick={() => {
                        handleUploadVideos(index)
                      }}
                    >
                      Upload
                    </Button>
                  </Grid>
                  <Grid
                    sx={{
                      width: '100%',
                      display: 'none',
                      justifyContent: 'center',
                      marginTop: '50px',
                    }}
                  >
                    {progress > 0 ? (
                      <CircularProgressWithLabel
                        value={progress}
                        style={{ color: 'white' }}
                      />
                    ) : loading ? (
                      <CircularProgress />
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <hr />
            </>
          ))}
        </CardContent>
      </MainCard>
    </>
  )
}

export default MyAssetBulkPage
