import { CssBaseline, StyledEngineProvider } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

// assets
import colors from './../assets/scss/_variables.scss'

// project imports
import componentsOverride from './overrides'
import themePalette from './palette'
import themeTypography from './typography'

const ThemeConfig = ({ children }) => {
  const color = colors

  const themeOption = useMemo(
    () => ({
      colors: color,
      heading: color.grey900,
      paper: color.paper,
      backgroundDefault: color.paper,
      background: color.darkPrimaryLight,
      darkTextPrimary: color.grey700,
      darkTextSecondary: color.grey500,
      textDark: color.grey900,
      menuSelected: color.secondaryDark,
      menuSelectedBack: color.secondaryLight,
      divider: color.grey200,
      fontFamily: ['Open Sans', 'Regular', 'sans-serif'].join(','),
      allVariants: {
        fontFamily: 'Open Sans, sans-serif',
        textTransform: 'none',
        fontSize: 16,
      },
    }),
    [color]
  )

  const themeOptions = useMemo(
    () => ({
      direction: 'ltr',
      palette: themePalette(themeOption),
      mixins: {
        toolbar: {
          minHeight: '48px',
          padding: '16px',
          '@media (min-width: 600px)': {
            minHeight: '48px',
          },
        },
      },
      shape: { borderRadius: 4 },
      typography: themeTypography(themeOption),
    }),
    [themeOption]
  )

  const themes = createTheme(themeOptions)
  //   themes.components = componentStyleOverrides(themeOption);
  themes.components = componentsOverride(themes)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

ThemeConfig.propTypes = {
  children: PropTypes.node,
}

export default ThemeConfig
