import React from 'react'
import { TableCell } from '@mui/material'

const MuiTableCell = ({ row, column, columnIndex }) => {
  let content = row[column.id]
  if (column.formatter) {
    content = column.formatter(row, column.formatExtraData)
  }
  return (
    <TableCell
      align={column.align || 'left'}
      sx={
        columnIndex == 0
          ? { padding: '6px 12px 6px 42px' }
          : { padding: '6px 12px 6px 6px' }
      }
    >
      {/* {column.formatter? column.formatter(row, column.formatExtraData): row[column.id]} */}
      {/* {column.formatter ? (
        <column.formatter row={row} formatExtraData={column.formatExtraData} />
      ) : (
        row[column.id]
      )} */}
      {/* {column.formatter? "formatter": row[column.id]} */}
      {content}
    </TableCell>
  )
}

export default MuiTableCell
