export const actionTypes = {
  SHOW_LOADER: '[Loader] SHOW_LOADER',
  HIDE_LOADER: '[Loader] HIDE_LOADER',
}

/**
 * Application reducer.
 * @redux
 * @type {Redux.Reducer}
 * @param {Redux.Store} state - The current redux state for errors.
 * @param {Redux.Action} action - A redux action
 * @return {Redux.Store} The updated redux state
 */
// const loaderReducer = (state = false, action) => {
//   switch (action.type) {
//     case actionTypes.SHOW_LOADER:
//       return action.data;
//     case actionTypes.HIDE_LOADER:
//       return action.data;
//     default:
//       return state;
//   }
// };

// export default loaderReducer;

const loaderReducer = (state = {}, action) => {
  function getActionName(actionType) {
    if (typeof actionType !== 'string') {
      return null
    }

    return actionType
  }

  const actionName = getActionName(action.type)

  if (!actionName) {
    return {
      ...state,
    }
  }

  if (action.type === actionTypes.SHOW_LOADER) {
    return {
      ...state,
      loading: true,
    }
  }

  // if (action.type.startsWith("GET_") || action.type.startsWith("CREATE_")) {
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // }

  if (action.type === actionTypes.HIDE_LOADER) {
    return {
      ...state,
      loading: false,
    }
  }

  // if (action.type.endsWith("_RECEIVED")) {
  //   return {
  //     ...state,
  //     loading: false,
  //   };
  // }

  if (action.type.endsWith('_ERROR')) {
    return {
      ...state,
      loading: false,
    }
  }

  return {
    ...state,
  }
}

export default loaderReducer
