import { Link } from 'react-router-dom'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

// project imports
import AuthWrapper from './AuthWrapper'
import AuthCardWrapper from './AuthCardWrapper'
import Logo from '../../components/Logo'
import AuthFooter from '../../components/cards/AuthFooter'

import {
  logoutUser,
  userDetails,
  PkceLoginAction,
  CongitoLoginAction,
  getProfile,
  pkceUserProfile,
  pkceStateAction,
  pkceCodeVerifierAction,
} from './redux/authAction'

import { connect } from 'react-redux'
import { useState } from 'react'

import {
  generateState,
  generateCodeVerifier,
  generateCodeChallenge,
} from '../../utils/pkce'

// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const LoginPage = ({ ...props }) => {
  const theme = useTheme()
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'))
  const [stateValue, setState] = useState(() => generateState())
  const [codeVerifier, setCodeVerifier] = useState(() => generateCodeVerifier())

  //PKCE LOGIN FLOW
  {
    /* const handleLogin = () => {
    props.pkceStateAction(stateValue)
    props.pkceCodeVerifierAction(codeVerifier)

    generateCodeChallenge(codeVerifier).then(data => {
      const client_id = process.env.REACT_APP_KEYCLOAK_CLIENTID
      const pkce_state = localStorage.getItem('pkceState')
      const query = `?client_id=${client_id}&response_type=code&scope=openid&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}/login/callback&code_challenge=${data}&state=${pkce_state}&code_challenge_method=S256`
      props.PkceLoginAction(query).then(response => {
        // return window.open(`http://localhost:8080/realms/Whilter-Ai/protocol/openid-connect/auth${query}`);
        window.location.href = `${process.env.REACT_APP_URL}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/auth${query}`
      })
    })
  }*/
  }

  //COGNITO LOGIN FLOW
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_AWS_COGNITO}/login?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENTID}&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI}`
  }
  return (
    <Grid item>
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <Button variant="contained" onClick={handleLogin}>
          Login
        </Button>
      </Stack>
    </Grid>
  )
}

const mapStateToProps = state => ({
  userlogin: state.auth.googleUser,
  facebooklogin: state.auth.facebookUser,
  user: state.auth.userinfo,
  loginPage: state.auth.loginPage,
  pkceStateValue: state.auth.pkcestatevalue,
  pkceCodeVerifier: state.auth.pkcecodeverifiervalue,
  pkceuserprofile: state.auth.pkceuserprofile,
  accesstoken: state.auth.pkceaccesstoken,
})
const mapActionsToProps = {
  logoutUser,
  CongitoLoginAction,
  userDetails,
  getProfile,
  PkceLoginAction,
  pkceStateAction,
  pkceCodeVerifierAction,
  pkceUserProfile,
}

export default connect(mapStateToProps, mapActionsToProps)(LoginPage)
