import React from 'react'
import { Field } from 'formik'
import { FormControl, TextField } from '@mui/material'
import InputMask from 'react-input-mask'

function InputMaskField(props) {
  const { label, name, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <InputMask {...field} {...rest}>
          {inputProps => (
            <FormControl fullWidth error={meta.error && meta.touched}>
              <TextField
                fullWidth
                {...inputProps}
                label={label}
                error={form.touched[name] && Boolean(form.errors[name])}
                helperText={form.touched[name] && form.errors[name]}
              />
            </FormControl>
          )}
        </InputMask>
      )}
    </Field>
  )
}

export default InputMaskField
