import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  allVideoClips: null,
}

const clipSlice = createSlice({
  name: 'clipSlice',
  initialState: initialState,
  reducers: {
    getVideoClips(state, action) {
      state.allVideoClips = action.payload
    },
  },
})

export default clipSlice
