import * as requestFromServer from './myassetCrud'

import myassetSlice from './myassetSlice'
const { actions } = myassetSlice

/* =========Get All MyAsset ========= */
export const MyAssetListAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetAllMyAsset()
      .then(response => {
        dispatch(actions.GetMyAsset({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Asset found'
        reject(error)
      })
  })
}

/* =========Get  MyAsset  with Pagination========= */
export const AllMyAssetListAction = pageable => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetMyAssetList(pageable)
      .then(response => {
        dispatch(actions.GetMyAssetListData({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Asset found'
        reject(error)
      })
  })
}
/* =========Delete My Assets ========= */
export const MyAssetListDeleteAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .DeleteMyAsset(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
/* =========Send Content Tags to server ========= */
export const MetadataAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .PostMetadata(data)
      .then(response => {
        dispatch(actions.sendContentTags({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No Tags found'
        reject(error)
      })
  })
}
/* =========Edit DraftAssets ========= */
export const UpdateMyAssetDraftAction = (id, formData) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .EditMyAssetDraft(id, formData)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Get Assets List By ID========= */
export const GetMyAssetListByIdAction = id => dispatch => {
  dispatch(actions.GetMyAssetData(null))
  return new Promise((resolve, reject) => {
    requestFromServer
      .GetMyAssetListById(id)
      .then(response => {
        dispatch(actions.GetMyAssetData(response.data))
        resolve(response)
      })
      .catch(error => {
        error.massage = 'No  Assets found'
        reject(error)
      })
  })
}

/* =========InReviewMyAsset========= */
export const InReviewMyAssetAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .InReviewMyAsset(id)
      .then(response => {
        // dispatch(actions.createSegment({ response }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Create Segment My Asset========= */
export const CreateSegmentAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .CreateSegment(data)
      .then(response => {
        dispatch(
          actions.createSegment({
            response,
          })
        )
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========EditSegment========= */

export const EditSegmentAction = (data, id) => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .EditSegment(data, id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Delete Segment========= */
export const DeleteSegmentAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .DeleteSegment(id)
      .then(response => {
        // dispatch(actions.createSegment({ response }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/*============Pubilsh MyAsset===========*/

export const PubilshMyAssetAction = id => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .PubilshMyAsset(id)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
