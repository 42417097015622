const __env = {}

let environment = 'dev' // can be dev, test or prod.

__env.name = environment
// environment specific constants
if (environment === 'prod') {
  __env.dataApi = ''
  __env.profileUploadFolder = 'prescription'
} else if (environment === 'test') {
  __env.dataApi = ''
  __env.profileUploadFolder = 'prescription'
} else {
  __env.dataApi = 'https://dev-api.whilter.com'
  __env.profileUploadFolder = 'prescription'
}
export default __env
