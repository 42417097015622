import __env from '../../../env'
import instance from '../../../middleware/axiosInstance'

export const GetAllMyAsset = () => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip`
  )
}

export const GetMyAssetList = pageable => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip/list/paging?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&search=${pageable.search}`
  )
}

export const PostMetadata = data => {
  // const assetid = localStorage.getItem('videoAssetId')
  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip`,
    data
  )
}

export const DeleteMyAsset = id => {
  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip/${id}`
  )
}

export const EditMyAssetDraft = (id, formData) => {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip/${id}`,
    formData
  )
}

export const GetMyAssetListById = id => {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip/${id}`
  )
}

export const CreateSegment = data => {
  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/segment`,
    data
  )
}

export const InReviewMyAsset = id => {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip/status?id=${id}&status=InReview`
  )
}

export const EditSegment = (data, id) => {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/segment/${id}`,
    data
  )
}

export const DeleteSegment = id => {
  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/segment/${id}`
  )
}

export const PubilshMyAsset = id => {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/my-asset/video-clip/status?id=${id}&status=Published`
  )
}
