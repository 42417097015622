import { combineReducers } from 'redux'

// reducer import
import authSlice from './../features/auth/redux/authSlice'
import sampleReducer from './sample/reducer'
import appSlice from './../features/category/redux/categorySlice'
import assetSlice from './../features/asset/redux/assetSlice'
import templateSlice from './../features/template/redux/templateSlice'
import clipSlice from './../features/videoclip/redux/clipSlice'
import loaderReducer from './loader/reducer'
import myassetSlice from './../features/myasset/redux/myassetSlice'
// import myttsSlice from '../features/library-objects/redux/videoLibrarySlice'
import librarySlice from '../features/library-objects/redux/videoLibrarySlice'
import topicSlice from '../features/trending-topics/redux/topicSlice'
import eventSlice from '../features/upcoming-events/redux/eventSlice'
import contentSlice from '../features/copyright-content/redux/contentSlice'
import entitySlice from '../features/entity-tags/redux/entitySlice'
import campaignSlice from '../features/campaigns/redux/campaignSlice'
import schemaSlice from '../features/schema/redux/schemaSlice'
import metadataSlice from '../features/metadata/redux/metadataSlice'
import configSlice from '../features/usecase-config/redux/configSlice'
import dagSlice from '../features/workflow-dag/redux/dagSlice'
// ==============================|| COMBINE REDUCER ||============================== //

const rootReducer = combineReducers({
  auth: authSlice.reducer, //auth
  app: appSlice.reducer,
  asset: assetSlice.reducer,
  template: templateSlice.reducer,
  videoclips: clipSlice.reducer,
  trendingtopic: topicSlice.reducer,
  sample: sampleReducer,
  loader: loaderReducer,
  myasset: myassetSlice.reducer,
  library:librarySlice.reducer,
  upcomingevent: eventSlice.reducer,
  copyrightcontent: contentSlice.reducer,
  entitytags: entitySlice.reducer,
  campaign: campaignSlice.reducer,
  schema:schemaSlice.reducer,
  metadata:metadataSlice.reducer,
  usecaseconfig: configSlice.reducer,
  dag:dagSlice.reducer,
  
})

export default rootReducer
