import PropTypes from 'prop-types'
// material
import { Paper, Typography } from '@mui/material'
import Loader from './Loader'
import LoaderImg from '../assets/images/users/loderFile.gif'

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string,
}

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1" sx={{m:5}}>
        {/* <img src={LoaderImg}  width={50} /> */}
        No Data Found
      </Typography>
      {/* <Typography variant="body2" align="center">
        No results found for &nbsp;
        <strong>&quot;{searchQuery}&quot;</strong>. Try checking for typos or
        using complete words.
  </Typography>*/}
    </Paper>
  )
}
