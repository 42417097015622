// assets
import SettingIcon from '@mui/icons-material/Settings'
import HelpIcon from '@mui/icons-material/Help'
import FeedbackIcon from '@mui/icons-material/Feedback'

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: 'reports',
  // title: 'Explore',
  type: 'group',
  children: [
    {
      id: 'current-month',
      title: 'Settings',
      type: 'item',
      url: '/sample-page',
      icon: SettingIcon,
      breadcrumbs: false,
    },
    // {
    //   id: 'last-quarter',
    //   title: 'Help',
    //   type: 'item',
    //   url: '/sample-page',
    //   icon: HelpIcon,
    //   breadcrumbs: false,
    // },
    // {
    //   id: 'year-end-sale',
    //   title: 'Send Feedback',
    //   type: 'item',
    //   url: '/sample-page',
    //   icon: FeedbackIcon,
    //   breadcrumbs: false,
    // },
    // {
    //     id: 'dashboard',
    //     title: 'Dashboard',
    //     type: 'item',
    //     url: '/dashboard',
    //     icon: HomeOutlinedIcon,
    //     breadcrumbs: false
    // }
  ],
}

export default dashboard
