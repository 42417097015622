import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import CognitoCallback from '../callback/CognitoCallback'
import Home from '../features/pages/home'
import HomeLayout from '../layout/home-layout'
import Logout from '../callback/Logout'

const AuthRoutes = () => {
  return (
    <Routes>
      <Route element={<HomeLayout />}>
        {/* <Suspense fallback="Loading..."> */}
        {/* <Route path="/" element={<LoginPage />} /> */}
        {/* <Route path="/login" element={<LoginPage />} /> */}
        <Route path="/login" element={<Home />} />
        {/* <Route path="/login/callback" element={<Callback />} />*/}
        <Route path="/callback" element={<CognitoCallback />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
        {/* </Suspense> */}
      </Route>
    </Routes>
  )
}

export default AuthRoutes
