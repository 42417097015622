import { Stack } from '@mui/material'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import {
  AssetCategoryAction,
  AssetSubCategoryAction,
  MetadataAction,
} from '../../redux/assetAction'
import FormikControl from '../../../../ui-components/form/formik/FormikControl'
import CategoriesField from './CategoriesField'

const TagsForm = ({ ...props }) => {
  const [tags, setTags] = React.useState([])

  return (
    <>
      <Stack direction="column" spacing={2}>
        <FormikControl
          control="input"
          type="text"
          placeholder="Name"
          label="Name"
          name="name"
        />

        <FormikControl
          control="input"
          type="text"
          placeholder="date/actor/subject"
          label="Batch"
          name="batch"
        />

        <FormikControl
          control="tags"
          value={tags}
          name="tags"
          label="Tags"
          onChange={tags => setTags(tags)}
          isMulti={true}
        />

        <CategoriesField
          categories={props.categories}
          subcategories={props.subcategories}
          assetCategoryAction={props.AssetCategoryAction}
          assetSubCategoryAction={props.AssetSubCategoryAction}
        />
      </Stack>
    </>
  )
}

const mapStateToProps = state => ({
  categories: state.asset.assetCategories,
  subcategories: state.asset.assetSubCategories,
})

const mapActionsToProps = {
  AssetCategoryAction,
  AssetSubCategoryAction,
  MetadataAction,
}

export default connect(mapStateToProps, mapActionsToProps)(TagsForm)
