// assets
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import TemplateIcon from '@mui/icons-material/Subscriptions'
import MyProjectsIcon from '@mui/icons-material/LocalMovies'
import PubcliAssetIcon from '@mui/icons-material/VideoCameraFront'
import PeopleIcon from '@mui/icons-material/People'
import MyAssetIcon from '@mui/icons-material/VideoLibrary'
import DashboardIcon from '@mui/icons-material/Dashboard'
import CategoryIcon from '@mui/icons-material/Category'
import MyAssetList from '../../features/myasset/myasset-list'

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
  id: 'pages',
  // title: 'Video Platform',
  // // caption: "Upload assets",
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/dashboard',
      icon: DashboardIcon,
      breadcrumbs: false,
    },
    {
      id: 'myprojects',
      title: 'My Projects',
      type: 'collapse',
      icon: MyProjectsIcon,

      children: [
        {
          id: 'video-clip',
          title: 'Recent',
          type: 'item',
          url: '/myprojects/recent',
          target: false,
        },
        {
          id: 'templates',
          title: 'All Projects',
          type: 'item',
          url: '/myprojects/all',
          target: false,
        },
      ],
    },
    {
      id: 'myassetslist',
      title: 'My Assets',
      type: 'item',
      url: '/myassets/list',
      icon: MyAssetIcon,
      breadcrumbs: false,
    },

    {
      id: 'assets',
      title: 'Public Assets',
      type: 'item',
      url: '/assets/list',
      icon: PubcliAssetIcon,
      breadcrumbs: false,
    },
    {
      id: 'templates',
      title: 'Templates',
      type: 'item',
      url: '/templates/list',
      icon: TemplateIcon,
    },

    // {
    //   id: "video-stages",
    //   title: "Video Stages",
    //   type: "collapse",
    //   icon: FileUploadIcon,

    //   children: [
    //     {
    //       id: "video-clip-new",
    //       title: "New",
    //       type: "item",
    //       url: "/videoclip/list",
    //       target: false,
    //     },
    //     {
    //       id: "video-clip-review",
    //       title: "Review",
    //       type: "item",
    //       url: "/upload/templates",
    //       target: false,
    //     },
    //     {
    //       id: "video-clip-completed",
    //       title: "Completed",
    //       type: "item",
    //       url: "/upload/templates",
    //       target: false,
    //     },
    //   ],
    // },

    {
      id: 'vendor',
      title: 'Vendor',
      type: 'item',
      url: '/vendor/list',
      icon: PeopleIcon,
      breadcrumbs: false,
    },
    {
      id: 'categories',
      title: 'Categories',
      type: 'item',
      url: '/category/list',
      icon: CategoryIcon,
      breadcrumbs: false,
    },
    {
      id: 'sub-category',
      title: 'SubCategories',
      type: 'item',
      url: '/subcategory/list',
      icon: CategoryIcon,
      breadcrumbs: false,
    },
    // {
    //   id: 'sample-page',
    //   title: 'Sample Page',
    //   type: 'item',
    //   url: '/sample-page',
    //   icon: ArticleOutlinedIcon,
    //   breadcrumbs: false,
    // },
    // {
    //     id: 'authentication',
    //     title: 'Authentication',
    //     type: 'collapse',
    //     icon: PersonOutlineOutlinedIcon,

    //     children: [
    //         {
    //             id: 'login',
    //             title: 'Login',
    //             type: 'item',
    //             url: '/auth/login',
    //             target: true
    //         },
    //         {
    //             id: 'register',
    //             title: 'Register',
    //             type: 'item',
    //             url: '/auth/register',
    //             target: true
    //         }
    //     ]
    // }
  ],
}

export default pages
