import * as requestFromServer from './authCrud'
import authSlice from './authSlice'
import jwt_decode from 'jwt-decode'

const { actions } = authSlice

/* =========AWS COGNITO ========= */
export const CongitoLoginAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    //  requestFromServer
    // .CognitoLogin()
    // .then(({ data }) => {
    //   console.log("data",data)
    // dispatch(actions.getPage({ data }))
    // resolve(data)
    //   })
    // .catch((error) => {
    //   reject(error);
    // });
  })
}

/* =========Get access token ========= */
export const GetAccessTokenAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .CognitoGetAccessToken(data)
      .then(({ data }) => {
        const { access_token, id_token, expires_in, refresh_token } = data
        dispatch(actions.congitoAccessToken({ access_token }))
        dispatch(actions.congitoRefreshToken({ refresh_token }))
        dispatch(actions.getIdToken({ id_token }))
        //  dispatch(actions.sessionTime({ expires_in}))
        dispatch(actions.isAuthenticated())
        resolve(access_token)
      })
      .catch(error => {
        reject(error)
      })
  })
}
/* =========Request for auth code pkce flow ========= */
export const PkceLoginAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    // requestFromServer
    //   .PkceLogin(data)
    //   .then(({ data }) => {
    dispatch(actions.getPage({ data }))
    resolve(data)
    // })
    // .catch((error) => {
    //   reject(error);
    // });
  })
}

/* =========Get access token ========= */
export const PostAuthoriseAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .PkceGetAccessToken(data)
      .then(({ data }) => {
        const { access_token, id_token, expires_in, refresh_token } = data
        dispatch(actions.pkceAccessToken({ access_token }))
        dispatch(actions.pkceRefreshToken({ refresh_token }))
        dispatch(actions.getIdToken({ id_token }))
        //  dispatch(actions.sessionTime({ expires_in}))
        dispatch(actions.isAuthenticated())
        resolve(access_token)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Get access token from refresh token ========= */
export const RefreshTokenAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .RefreshToken()
      .then(({ data }) => {
        const { access_token } = data
        //  dispatch(actions.pkceAccessToken({ access_token }))
        //  dispatch(actions.sessionTime({ expires_in}))
        dispatch(actions.isAuthenticated())
        resolve(access_token)
      })
      .catch(error => {
        reject(error)
      })
  })
}

/* =========Logout ========= */
export const logoutUser = () => dispatch => {
  return new Promise((resolve, reject) => {
    // requestFromServer.Cognitologout()
    //  .then((response) => {
    // console.log("response", response);
    dispatch(actions.removeAccess())
    resolve()
      //  })

      .catch(error => {
        error.message = 'error'
        reject(error)
      })
  })
}

export const userDetails = data => dispatch => {
  dispatch(actions.UserDetails(data))
}
export const pkceStateAction = data => dispatch => {
  dispatch(actions.pkceState(data))
}
export const pkceCodeVerifierAction = data => dispatch => {
  dispatch(actions.pkceCodeVerifier(data))
}

/* ========= SocialLogin ========= */
export const SociaLoginAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    const userData = jwt_decode(data)
    dispatch(actions.isAuthenticated())
    localStorage.setItem('LoginDetails', JSON.stringify(userData))
    const state = {
      userdata: localStorage.getItem('LoginDetails')
        ? JSON.parse(localStorage.getItem('LoginDetails'))
        : null,
    }
    dispatch(userDetails(state))
    resolve(userData)
  })
}

export const FacebookLoginAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(actions.isAuthenticated())
    localStorage.setItem('LoginDetailsFacebook', JSON.stringify(data))
    dispatch(actions.facebookUserDetails(data))
    resolve(data)
  })
}

export const pkceUserProfile = () => dispatch => {
  const data = localStorage.getItem('pkce_id_token')
  const decodedata = jwt_decode(data)
  dispatch(actions.pkceUserProfile({ decodedata }))
}

/* ========= GetUser ========= */
export const getProfile = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .userProfile()
      .then(response => {
        dispatch(actions.userInfo({ response }))
        resolve(response)
      })
      .catch(error => {
        error.message = 'No User found'
        reject(error)
      })
  })
}
/* ========= Login ========= */
export const loginAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .login(data)
      .then(({ data }) => {
        const { access_token, refresh_token } = data
        dispatch(actions.getAccessToken({ access_token }))
        dispatch(actions.getRefreshToken({ refresh_token }))
        dispatch(actions.isAuthenticated())
        getProfile()
        resolve(access_token)
      })
      .catch(error => {
        reject(error)
      })
  })
}
/* =========Get admin token ========= */

export const EnumDataAction = () => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .EnumData()
      .then(response => {
        dispatch(actions.enumdata(JSON.stringify(response?.data)))
        resolve(response)
      })
      .catch(error => {
        error.message = 'error'
        reject(error)
      })
  })
}

/* ========= Signup ========= */
export const registerAction = data => dispatch => {
  return new Promise((resolve, reject) => {
    requestFromServer
      .register(data)
      .then(({ data }) => {
        const { accessToken } = data
        //  dispatch(actions.register({ accessToken }));
        resolve(accessToken)
      })
      .catch(error => {
        error.message = 'Invalid credentials'
        dispatch(actions.catchError({ error }))
        reject(error)
      })
  })
}

export const ForgotPassword = data => dispatch => {
  return new Promise(function (resolve, reject) {
    requestFromServer
      .PasswordChange(data)
      .then(response => {
        dispatch(
          actions.catchSuccessMessage({
            success: 'Password Changed successfully',
          })
        )
        const passwordResponse = response.data
        dispatch(actions.passwordData({ passwordResponse }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

export const ResetPassword = (username, password) => (dispatch, getState) => {
  return new Promise(function (resolve, reject) {
    const authToken = localStorage.getItem('access_token')
    requestFromServer
      .PasswordReset(authToken, username, password)
      .then(response => {
        console.log('response', response)
        dispatch(
          actions.catchSuccessMessage({
            success: 'Password Changed successfully',
          })
        )
        const resetPasswordResponse = response.data
        dispatch(actions.resetPasswordData({ resetPasswordResponse }))
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
