import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

function RadioButtons(props) {
  const { label, name, row = false, options, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <FormControl error={meta.error && meta.touched}>
          <FormLabel>{label}</FormLabel>
          <RadioGroup {...field} {...rest} row={row}>
            {options.map(option => {
              return (
                <React.Fragment key={option.key}>
                  <FormControlLabel
                    value={option.value}
                    // checked={field.value.includes(option.value)}
                    disabled={option.disabled}
                    control={<Radio />}
                    label={option.key}
                  />
                </React.Fragment>
              )
            })}
          </RadioGroup>
          <ErrorMessage component={TextError} name={name} />
        </FormControl>
      )}
    </Field>
  )
}

export default RadioButtons
