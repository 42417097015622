// third-party
import { merge } from 'lodash'

// project import
import Autocomplete from './Autocomplete'
import Badge from './Badge'
import Button from './Button'
import Card from './Card'
import CardContent from './CardContent'
import Checkbox from './Checkbox'
import Chip from './Chip'
import IconButton from './IconButton'
import InputLabel from './InputLabel'
import Input from './Input'
import LinearProgress from './LinearProgress'
import Link from './Link'
import Lists from './Lists'
import OutlinedInput from './OutlinedInput'
import Paper from './Paper'
import Tab from './Tab'
import Table from './Table'
import Tabs from './Tabs'
import Typography from './Typography'
import Slider from './Slider'
import Divider from './Divider'
import Avatar from './Avatar'

// ==============================|| OVERRIDES - MAIN ||============================== //

export default function ComponentsOverrides(theme) {
  return merge(
    Autocomplete(theme),
    Avatar(theme),
    Button(theme),
    Badge(theme),
    Card(theme),
    CardContent(),
    Checkbox(theme),
    Chip(theme),
    Divider(theme),
    IconButton(theme),
    Input(theme),
    InputLabel(theme),
    LinearProgress(),
    Link(),
    Lists(theme),
    OutlinedInput(theme),
    Paper(theme),
    Slider(theme),
    Tab(theme),
    Table(theme),
    Tabs(),
    Typography()
  )
}
