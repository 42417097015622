import { useEffect, useState } from 'react'

// material-ui
import { Grid, Button } from '@mui/material'

// project imports
import { FilledInput, FormControl, InputAdornment, Stack } from '@mui/material'

import { SearchOutlined } from '@ant-design/icons'

// progress loader for waiting
//import ProgressLoader from "../../ui-components/ProgressLoader";
//import Loader from "../../components/Loader";

// ==============================|| DEFAULT DASHBOARD ||============================== //

const Home = () => {
  const [isLoading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(false)
  }, [])

  //COGNITO LOGIN FLOW
  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_AWS_COGNITO}/login?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENTID}&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI}`
  }

  return (
    <>
      <Grid
        style={{
          textAlign: 'center',
          alignItems: 'center',
          margin: 'auto',
          width: '100%',
        }}
      >
        <Stack>
          <h1
            style={{
              fontFamily: 'revert',
              lineHeight: '64px',
              fontSize: '52px',
              marginTop: '50px',
              marginBottom: '0px',
              fontWeight: '600',
            }}
          >
            UNLEASH YOUR CUSTOMER NEEDS
          </h1>
          <h1
            style={{
              fontFamily: 'revert',
              lineHeight: '64px',
              fontSize: '26px',
              marginBottom: '0px',
              fontWeight: '100',
            }}
          >
            Elevate Video Production to Unprecedented Scales
          </h1>
        </Stack>
        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={1}
          sx={{
            marginTop: '80px',
          }}
        >
          <Button
            variant="contained"
            onClick={handleLogin}
            sx={{
              lineHeight: '72px',
              fontSize: '32px',
              color: '#ffffff',
              backgroundImage:
                'linear-gradient(to right, rgb(8, 0, 255), #ff00d4)',
              borderColor: '#46b8da',
            }}
          >
            Let's Start and Dive Into...
          </Button>
        </Stack>
        {/* <FormControl
          sx={{
            width: '50%',
            marginRight: '10px',
          }}
        >
          <FilledInput
            size="small"
            id="header-search"
            sx={{
              fontSize: '28px',
              '& input::placeholder': {
                fontSize: '28px',
              },
              paddingBottom: '10px',
              paddingLeft: '20px',
            }}
            startAdornment={
              <InputAdornment position="start" sx={{ marginTop: '10px' }}>
                <SearchOutlined />
              </InputAdornment>
            }
            aria-describedby="header-search-text"
            inputProps={{
              'aria-label': 'weight',
            }}
            placeholder="Search..."
          />
        </FormControl> */}
        <Grid item xs={12} mt={20} sx={{ display: 'block' }}>
          <Grid
            item
            xs={3}
            sx={{
              display: 'inline',
              padding: '0px',
              margin: '10px',
              backgroundColor: '#3498db',
              opacity: '0.5',
            }}
          >
            <img
              style={{
                width: '15%',
                height: '100%',
                objectFit: 'cover',
              }}
              src="https://cdn.whilter.com/website/videos/lemeri.jpg"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'inline',
              padding: '0px',
              margin: '10px',
              backgroundColor: '#3498db',
              opacity: '0.4',
            }}
          >
            <img
              style={{
                width: '15%',
                height: '100%',
                objectFit: 'cover',
              }}
              src="https://cdn.whilter.com/website/videos/GCTN.jpg"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'inline',
              padding: '0px',
              margin: '10px',
              backgroundColor: '#3498db',
              opacity: '0.3',
            }}
          >
            <img
              style={{
                width: '15%',
                height: '100%',
                objectFit: 'cover',
              }}
              src="https://cdn.whilter.com/website/videos/Puneet.jpg"
            />
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              display: 'inline',
              padding: '0px',
              margin: '10px',
              backgroundColor: '#3498db',
              opacity: '0.2',
            }}
          >
            <img
              style={{
                width: '15%',
                height: '100%',
                objectFit: 'cover',
              }}
              src="https://cdn.whilter.com/website/videos/kafkarev.jpg"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default Home
