import { useState } from 'react'
import { filter } from 'lodash'

const EVENT_TYPES = {
  SEARCH: 'search',
  SORT: 'sort',
  PAGE_CHANGE: 'pageChange',
  ROWS_PER_PAGE_CHANGE: 'rowsPerPageChange',
}

const useTableHelper = (paginationProps, searchQuery) => {
  const [page, setPage] = useState(paginationProps.page)

  const [order, setOrder] = useState(paginationProps.order)

  const [orderBy, setOrderBy] = useState(paginationProps.orderBy)

  const [rowsPerPage, setRowsPerPage] = useState(paginationProps.rowsPerPage)

  const [search, setSearch] = useState(searchQuery)

  const [eventType, setEventType] = useState('')

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1
    }
    if (b[orderBy] > a[orderBy]) {
      return 1
    }
    return 0
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index])
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order
      return a[1] - b[1]
    })
    if (query) {
      return filter(
        array,
        _records =>
          _records[orderBy].toLowerCase().indexOf(query.toLowerCase()) !== -1
      )
    }
    return stabilizedThis.map(el => el[0])
  }

  const handleSearch = event => {
    setSearch(event.target.value)
    setPage(0)
    setEventType(EVENT_TYPES.SEARCH)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setEventType(EVENT_TYPES.SORT)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
    setEventType(EVENT_TYPES.PAGE_CHANGE)
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
    setEventType(EVENT_TYPES.ROWS_PER_PAGE_CHANGE)
  }

  return {
    descendingComparator,
    getComparator,
    applySortFilter,
    handleRequestSort,
    handleChangePage,
    handleChangeRowsPerPage,
    handleSearch,
    eventType,
    search,
    pagination: {
      page,
      order,
      orderBy,
      rowsPerPage,
    },
  }
}

export default useTableHelper
