import { Navigate, useLocation } from 'react-router-dom'

export const Protected = ({ isLoggedIn, children }) => {
  const location = useLocation()
  //TODO: below clear interval need to place on some other appropriate place where all pages can be used
  //or alternatively, approach need to change in AnalysingAssetEditPage.js where interval created
  clearInterval(localStorage.getItem('handleIntervalSubscription'))
  localStorage.removeItem('handleIntervalSubscription')

  clearInterval(localStorage.getItem('handleTemplate'))
  localStorage.removeItem('handleTemplate')

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ path: location.pathname }} />
  }
  return children
}
