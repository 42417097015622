// types
import { createSlice } from '@reduxjs/toolkit'

// initial state
const initialState = {
  sample: '',
}

// ==============================|| SLICE - SAMPLE ||============================== //

const sample = createSlice({
  name: 'sample',
  initialState,
  reducers: {
    setItem(state, action) {
      state.sample = action.payload.sample
    },
  },
})

export default sample.reducer

export const { setItem } = sample.actions
