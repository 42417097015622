import { Stack } from '@mui/material'
import React, { useMemo } from 'react'
import { connect } from 'react-redux'
import { Typography, CardContent, Button, Box, Divider } from '@mui/material'
import {
  AssetCategoryAction,
  AssetSubCategoryAction,
  MetadataAction,
  UpdateAssetDraftAction,
} from '../../../redux/assetAction'
import FormikControl from '../../../../../ui-components/form/formik/FormikControl'
import CategoriesField from '../../../asset-form/components/CategoriesField'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import MainCard from '../../../../../components/cards/MainCard'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const AssetInfoEdit = ({ ...props }) => {
  const [tags, setTags] = React.useState([])
  const location = useLocation()
  const navigate = useNavigate()
  const id = location.state.id
  const handleAssets = formData => {
    props
      .UpdateAssetDraftAction(id, formData)
      .then(response => {
        if (response?.status == 200) {
          toast.success('Asset updated successfully', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
          navigate(`/assets/edit/${id}`)
        } else {
          toast.error('Something went wrong', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
        }
      })
      .catch(error => {
        toast.error('Something went wrong', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        })
      })
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }
  return (
    <>
      <MainCard title={<Typography variant="h3">Edit Public Asset</Typography>}>
        <CardContent>
          <Formik
            initialValues={{
              name: location?.state?.name,
              tags: location.state.tags,
              batch: location.state.batch,
              categories: location.state.categories,
            }}
            enableReinitialize={true}
            validate={values => {
              const errors = {}

              if (!values.name) {
                errors.name = 'Name is required'
              }
              if (!values.batch) {
                errors.batch = 'Batch is required'
              }
              if (values.tags?.length == 0) {
                errors.tags = 'Tag is required'
              }
              if (isEmpty(values.categories)) {
                errors.categories = 'Please add atleast one category'
              }

              return errors
            }}
            onSubmit={handleAssets}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Stack direction="column" spacing={2}>
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="Name"
                    label="Name"
                    name="name"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    placeholder="date/actor/subject"
                    label="Batch"
                    name="batch"
                  />

                  <FormikControl
                    control="tags"
                    value={tags}
                    name="tags"
                    label="Tags"
                    onChange={tags => setTags(tags)}
                    isMulti={true}
                  />

                  {/* <CategoriesField
                    categories={props.categories}
                    subcategories={props.subcategories}
                    assetCategoryAction={props.AssetCategoryAction}
                    assetSubCategoryAction={props.AssetSubCategoryAction}
                  /> */}
                  {props.error && <span>{props.error}</span>}
                </Stack>
                <Divider sx={{ marginTop: '20px' }} />
                <Box
                  sx={{
                    mt: 3,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItem: 'flex-end',
                    padding: '10px',
                  }}
                >
                  <Button type="submit" variant="contained">
                    Submit
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </CardContent>
      </MainCard>
    </>
  )
}

const mapStateToProps = state => ({
  categories: state.asset.assetCategories,
  subcategories: state.asset.assetSubCategories,
})

const mapActionsToProps = {
  AssetCategoryAction,
  AssetSubCategoryAction,
  MetadataAction,
  UpdateAssetDraftAction,
}

export default connect(mapStateToProps, mapActionsToProps)(AssetInfoEdit)
