import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants'
const initialState = {
  allMyTts: [],
  myTtsList: [],
  allMyLs: [],
  myLsList: [],
  myVcList: [],
  myTsList: [],
  allKdenCache:[],
  allFinalVideos:[],
  allFinalVideosList:[],
  pageabletts: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },

   pageableLs: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
   
    pageableVc: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
   
     pageableTs: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
     
       pageablels: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },

  pageablekden: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
   
  pageableFinalVideos: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },

  pageableFinalVideoList: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
  metaData: null,
  getMyAssetById: null,
  createSegment: null,
}

const librarySlice = createSlice({
  name: 'librarySlice',
  initialState: initialState,
  reducers: {
    GetMyTts(state, action) {
      state.allMyTts = action.payload
    },

    GetGlobalTtsListData(state, action) {
      state.myTtsList = action.payload.response.data.content
      state.pageabletts = {
        ...state.pageabletts,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },

    GetTemplateTtsListData(state, action) {
      state.myTtsList = action.payload.response.data.content
      state.pageabletts = {
        ...state.pageabletts,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },

    GetMyAssetListData(state, action) {
      state.myTtsList = action.payload.response.data.content
      state.pageabletts = {
        ...state.pageabletts,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },

    GetMyLipSyncedListData(state, action) {
      
      state.myLsList = action.payload.response.data.content
      state.pageableLs = {
        ...state.pageableLs,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },

    GetMyVoiceClonedListData(state, action) {
      
      state.myVcList = action.payload.response.data.content
      state.pageableVc = {
        ...state.pageableVc,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },

    GetMyTransliterationListData(state, action) {
      
      state.myTsList = action.payload.response.data.content
      state.pageableTs = {
        ...state.pageableTs,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },

    GetAllKdenCacheData(state, action) {
      
      state.allKdenCache = action.payload.content
      state.pageablekden = {
        ...state.pageablekden,
        pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
      }
    },

    GetAllFinalVideosData(state, action) {
      
      state.allFinalVideos = action.payload.content
      state.pageableFinalVideos = {
        ...state.pageableFinalVideos,
        pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
      }
    },

    GetFinalVideosData(state, action) {
      state.allFinalVideosList = action.payload.content
      state.pageableFinalVideoList = {
        ...state.pageableFinalVideoList,
        pageNo: action.payload.pageNo,
                pageSize: action.payload.pageSize,
                totalElements: action.payload.totalElements,
                totalPages: action.payload.totalPages,
      }
    },

    GetNamesTransliterationListData(state, action) {
      
      state.myTsList = action.payload.response.data.content
      state.pageableTs = {
        ...state.pageableTs,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },

    GetPhrasesTransliterationListData(state, action) {
      
      state.myTsList = action.payload.response.data.content
      state.pageableTs = {
        ...state.pageableTs,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },

    GetTemplateSpecificTransliterationListData(state, action) {
      
      state.myTsList = action.payload.response.data.content
      state.pageableTs = {
        ...state.pageableTs,
        pageNo: action.payload.response.data.number,
        pageSize: action.payload.response.data.size,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },
  },
})

export default librarySlice
