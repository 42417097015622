import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './rootReducer'

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistConfig = {
  blacklist: [
    'app',
    'myasset',
    'asset',
    'trendingtopic',
    'upcomingevent',
    'copyrightcontent',
    'template',
    'entitytags',
    'campaign',
    'schema',
    'metadata',
    'library',
    'usecaseconfig',
    'dag'
    
  ],
  key: 'whilter-video',
  storage,
}
const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
  ],
  devTools: process.env.NODE_ENV !== 'production',
})

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
const persistor = persistStore(store)

// export default store;
export { store, persistor }
