import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants/index'

const initialState = {
 getMetadataList: [],
 getSingleMetadata:null,
 metadataPagination:[],
 getMetadataItems:[],
 pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
  getClientRequestList:[],
  clientRequestPagination:[],
  clientRequestPageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
}

const metadataSlice = createSlice({
    name: 'metadata',
    initialState: initialState,
    reducers: {
        metadataFetched: (state, action) => {
        state.getMetadataList = action.payload
      },

      metadataFetchedById: (state, action) => {
        state.getSingleMetadata = action.payload
      },

      metadataPaginationFetched: (state, action) => {
        state.metadataPagination = action.payload.content
        state.pageable = {
          ...state.pageable,
          pageNo: action.payload.pageNo,
          pageSize: action.payload.pageSize,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
        }
      },

      clientRequestFetched: (state, action) => {
        state.getClientRequestList = action.payload
      },

      clientRequestPaginationFetched: (state, action) => {
        state.clientRequestPagination = action.payload.content
        state.clientRequestPageable = {
          ...state.clientRequestPageable,
          pageNo: action.payload.pageNo,
          pageSize: action.payload.pageSize,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
        }
      },
      metadataItemsFetched:(state,action)=>{
          state.getMetadataItems = action.payload
      }
    }
    })

    export default metadataSlice
