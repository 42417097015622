import PropTypes from 'prop-types'

// material-ui
import { AppBar, Box, Button, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// project imports
import LogoSection from '../logo-section'

// assets
import LoginBar from '../../../features/auth/LoginBar'
import { drawerWidth } from '../../../utils/constant'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ drawerOpen, handleDrawerToggle, userInfo }) => {
  const theme = useTheme()
  const iconBackColor = 'grey.100'
  const iconBackColorOpen = 'grey.200'
  const handleLogin = () => {}
  return (
    <>
      {/* logo & toggler button */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: drawerOpen ? theme.transitions.create('width') : 'none',
        }}
      >
        <Toolbar>
          <Box
            sx={{
              width: drawerWidth - 40,
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('md')]: {
                width: 'auto',
              },
            }}
          >
            <Box component="div">
              <LogoSection />
            </Box>
          </Box>

          <Box sx={{ flexGrow: 1 }} />
          {/* <Button variant="contained" onClick={handleLogin}>
            <LoginBar />
          </Button> */}
        </Toolbar>
      </AppBar>
    </>
  )
}

Header.propTypes = {
  handleDrawerToggle: PropTypes.func,
  drawerOpen: PropTypes.bool,
}

export default Header
