import React from 'react'
import Input from './Input'
import SelectField from './SelectField'
import RadioButtons from './RadioButtons'
import CheckboxGroup from './CheckboxGroup'
import CheckboxField from './CheckboxField'

import TagsField from './TagsField'
import AutocompleteField from './AutocompleteField'
import SwitchField from './SwitchField'
import SwitchGroup from './SwitchGroup'
import MuiDatePicker from './MuiDatepicker'
import MuiTimePicker from './MuiTimepicker'
import MuiDateTimePicker from './MuiDateTimepicker'
import InputMaskField from './InputMaskField'
import ObjectField from './ObjectField'
import RangesField from './RangesField'

function FormikControl(props) {
  const { control, ...rest } = props
  switch (control) {
    case 'input':
      return <Input {...rest} />
    case 'select':
      return <SelectField {...rest} />
    case 'radio':
      return <RadioButtons {...rest} />
    case 'checkbox':
      return <CheckboxField {...rest} />
    case 'checkbox-group':
      return <CheckboxGroup {...rest} />
    case 'datepicker':
      return <MuiDatePicker {...rest} />
    case 'timepicker':
      return <MuiTimePicker {...rest} />
    case 'datetimepicker':
      return <MuiDateTimePicker {...rest} />
    case 'tags':
      return <TagsField {...rest} />
    case 'object':
      return <ObjectField {...rest} />
    case 'autocomplete':
      return <AutocompleteField {...rest} />
    case 'switch':
      return <SwitchField {...rest} />
    case 'switch-group':
      return <SwitchGroup {...rest} />
    case 'input-mask':
      return <InputMaskField {...rest} />
      case 'ranges':
        return <RangesField {...rest} />

    default:
      return null
  }
}

export default FormikControl
