export const defaultPage = 0
export const defaultSize = 10
export const defaultSortBy = 'createdDate'
export const defaultSortDir = 'desc'
export const defaultTotalPages = 1
export const defaultTotalElements = 0
export const defaultSearch = ''

export const defaultPageable = {
  pageNo: defaultPage,
  pageSize: defaultSize,
  sortBy: defaultSortBy,
  sortDir: defaultSortDir,
}
