import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useTheme } from '@mui/material'

const AutocompleteField = ({
  isMulti,
  value,
  options,
  onChange,
  disabled = false,
  component = false,
  placeholder,
  error,
  ...props
}) => {
  const theme = useTheme()
  const [tags, setTags] = useState([])
  const [inputValue, setInputValue] = useState('')

  const [colorError, setColorError] = useState(false)

  useEffect(() => {
    setColorError(error)
  }, [error])

  const customStyles = {
    control: (baseStyles, state) =>
      colorError
        ? {
            ...baseStyles,
            baseStyles: `0 0 0 1px ${theme.palette.error.main} !important`,
            // baseStyles: `0 0 0 1px #000000 !important`,
            borderColor: `${theme.palette.error.main} !important`,
            color: `${theme.palette.error.main} !important`,
          }
        : {
            ...baseStyles,
            //   borderColor: state.isFocused ? 'grey' : `${theme.palette.error.main}`,
          },
    container: styles => ({
      padding: 0,
    }),
    placeholder: defaultStyles =>
      colorError
        ? {
            ...defaultStyles,
            color: `${theme.palette.error.main} !important`,
          }
        : {
            ...defaultStyles,
            // color: `${theme.palette.grey[500]}`,
            color: 'black',
            fontWeight: theme.typography.fontWeightMedium,
          },
    singleValue: (styles, { data }) => {
      return {
        ...styles,
        fontWeight: theme.typography.fontWeightMedium,
      }
    },
    singleValueLabel: (styles, { data }) => ({
      ...styles,
      // color: theme.palette.common.white,
      color: 'black',
      fontSize: theme.typography.fontSize,
      padding: '2px 4px',
      fontWeight: theme.typography.fontWeightMedium,
    }),
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        borderRadius: '20px',
        padding: '2px',
      //  backgroundColor: theme.palette.primary.light,
       // color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightMedium,
      }
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      // color: theme.palette.common.white,
      color: 'black',
      fontSize: theme.typography.fontSize,
      padding: '2px 4px',
      fontWeight: theme.typography.fontWeightMedium,
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      // color: data.color,
      color: 'black',
      fontSize: theme.typography.body1.fontSize,
      ':hover': {
        //   backgroundColor: data.color,
        // color: 'white',
        color: 'black',
        cursor: 'pointer',
        fontWeight: theme.typography.fontWeightMedium,
      },
    }),
    menuPortal: base => ({ ...base, zIndex: 9999 }),
  }

  useEffect(() => {
    if (value) {
      setTags(
        isMulti
          ? value.map(tag => ({ label: tag, value: tag }))
          : { label: value, value: value }
      )
    } else {
      setTags(isMulti ? [] : '')
    }
  }, [isMulti, value])

  const updateTags = tags => {
    setTags(tags)

    if (!tags) {
      onChange([])
      return
    }
    onChange(isMulti ? tags.map(tag => tag.value) : tags.value)
  }
  return (
    <>
      <div>
        <Select
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          styles={customStyles}
          //   components={{
          //     DropdownIndicator: component ? DropdownIndicator : null,
          //   }}
          // menuIsOpen={false}
          isMulti={isMulti}
          isDisabled={disabled}
          // options={(options || []).map(option => ({
          //   label: option,
          //   value: option,
          // }))}
          options={options || []}
          placeholder={placeholder || 'Enter Values'}
          value={tags}
          inputValue={inputValue}
          onInputChange={val => setInputValue(val.toLowerCase())}
          onChange={tags => updateTags(tags)}
          //   MenuProps={MenuProps}
        >
          {/* {options.map(option => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))} */}
        </Select>
      </div>
    </>
  )
}

export default AutocompleteField
