import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants'
const initialState = {
  allMyAsset: [],
  myAssetList: [],
  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
  metaData: null,
  getMyAssetById: null,
  createSegment: null,
}

const myassetSlice = createSlice({
  name: 'myassetSlice',
  initialState: initialState,
  reducers: {
    GetMyAsset(state, action) {
      state.allMyAsset = action.payload
    },

    GetMyAssetListData(state, action) {
      state.myAssetList = action.payload.response.data.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.response.data.pageNo,
        pageSize: action.payload.response.data.pageSize,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },
    sendContentTags(state, action) {
      state.metaData = action.payload
    },
    GetMyAssetData(state, action) {
      state.getMyAssetById = action.payload
    },
    createSegment(state, action) {
      state.postSegment = action.payload
    },
  },
})

export default myassetSlice
