import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Switch,
} from '@mui/material'

function SwitchGroup(props) {
  const { label, name, options, row = false, ...rest } = props
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => (
          <FormControl error={meta.error && meta.touched}>
            <FormLabel>{label}</FormLabel>
            <FormGroup row={row}>
              {options.map(option => {
                return (
                  <React.Fragment key={option.key}>
                    <FormControlLabel
                      {...field}
                      {...rest}
                      value={option.value}
                      checked={field.value.includes(option.value)}
                      disabled={option.disabled}
                      control={<Switch />}
                      label={option.key}
                    />
                  </React.Fragment>
                )
              })}
            </FormGroup>
            <ErrorMessage component={TextError} name={name} />
          </FormControl>
        )}
      </Field>
    </>
  )
}

export default SwitchGroup
