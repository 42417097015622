import React from 'react'
import { useEffect, useState } from 'react'
import reports from './reports'
import pages from './pages'
import influencerpages from './content-creator'
import adminpages from './whilter-content-contributor'
import whilteradmin from './whilter-admin'
import superadminpages from './super-admin'
import { ROLES } from '../../constants'
import { shallowEqual, useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'
// ==============================|| MENU ITEMS ||============================== //
//const role=ROLES.ADMIN;

const MenuItems = () => {
  const [roles, setRoles] = useState(null)
  const { cognitoaccesstoken } = useSelector(
    ({ auth }) => ({
      cognitoaccesstoken: auth.cognitoaccesstoken,
    }),
    shallowEqual
  )

  useEffect(() => {
    const data = jwt_decode(cognitoaccesstoken?.access_token)
    const Roles = data['cognito:groups']
    const roles = Roles?.values()
    const whilter_roles = roles?.next().value
    setRoles(whilter_roles)
  }, [cognitoaccesstoken?.access_token])

  if (roles === 'SUPER_ADMIN') {
    return {
      items: [superadminpages, reports],
    }
  } else if (roles === 'WHILTER_CONTENT_CONTRIBUTOR') {
    return {
      items: [adminpages, reports],
    }
  } else if (roles === 'CONTENT_CREATOR') {
    return {
      items: [influencerpages, reports],
    }
  } else if (roles === 'WHILTER_ADMIN') {
    return {
      items: [whilteradmin, reports],
    }
  }

  return { items: [] }
}

export default MenuItems
