import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants'
const initialState = {
  getallevent: [],
  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
  createEventId: null,
  create_event: null,
}
const eventSlice = createSlice({
  name: 'eventSlice',
  initialState: initialState,
  reducers: {
    getEvent(state, action) {
      state.getallevent = action.payload.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.pageNo,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      }
    },
    createEvent(state, action) {
      state.createEventId = action.payload
    },
    eventData(state, action) {
      state.create_event = action.payload
    },
  },
})
export default eventSlice
