import React from 'react'
import { Field } from 'formik'
import { FormControl, TextField } from '@mui/material'
import { ErrorMessage } from 'formik'
import  TextError  from './TextError'

function Input(props) {
  const { label, name, readOnly = false, ...rest } = props
  console.log('read', readOnly,label,name)
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        console.log("name",form.errors[name],name)
        return(
        <FormControl fullWidth error={meta.error && meta.touched}>
          <TextField
            fullWidth
            {...field}
            {...rest}
            label={label}
            InputProps={{
              readOnly: readOnly, 
            }}
            // error={form.touched[name] && Boolean(form.errors[name])}
            // helperText={form.touched[name] && form.errors[name]}
          />
          <ErrorMessage component={TextError} name={name} />
        </FormControl>
      )}}
    </Field>
  )
}

export default Input
