import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants/index'

const initialState = {
  campaigns: [],
  templateByTags: [],
  campaignsPaginationList: [],
  profiles: [],
  profilesPaginationList: [],
  profileInstances: [],
  get_single_campaign:null,
  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState: initialState,
  reducers: {
    campaignsFetched: (state, action) => {
      state.campaigns = action.payload
    },
    campaignsPaginationFetched: (state, action) => {
      state.campaignsPaginationList = action.payload.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.pageNo,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      }
    },
    templateFetched: (state, action) => {
      state.templateByTags = action.payload
    },
    profilesFetched: (state, action) => {
      state.profiles = action.payload
    },
    profilesPaginationFetched: (state, action) => {
      state.profilesPaginationList = action.payload.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.pageNo,
        pageSize: action.payload.pageSize,
        totalElements: action.payload.totalElements,
        totalPages: action.payload.totalPages,
      }
    },
    getSingleCampaign: (state, action) => {
      state.get_single_campaign = action.payload
    },

    getProfileInstances: (state, action) => {
      state.profileInstances = action.payload
    },
  },
})

export default campaignSlice
