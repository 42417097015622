// routing
import Routes from './routes'

// defaultTheme
import ThemeConfig from './theme'
import jwt_decode from 'jwt-decode'

// project imports
import NavigationScroll from './layout/NavigationScroll'
import { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import LoadingSplashScreen from './components/LoadingSplashScreen'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'

// ==============================|| APP ||============================== //

const App = ({ ...props }) => {
  const [isLogged, setisLogged] = useState(false)
  const [userInfo, setUserInfo] = useState('')
  const [loading, setLoading] = useState(true)

  // AWS COGNITO TOKEN FLOW
  const { idtoken } = useSelector(
    ({ auth }) => ({
      idtoken: auth.cognitoidtoken,
    }),
    shallowEqual
  )

  const checkStorage = useCallback(() => {
    setLoading(true)
    if (localStorage.getItem('cognito_id_token')) {
      setisLogged(true)
      const data = jwt_decode(idtoken?.id_token)

      setUserInfo(data)
    } else {
      setisLogged(false)
      //   setTimeout(() => {
      //     handleLogin();
      //   }, 100);
    }
    setLoading(false)
  }, [idtoken?.id_token])

  useEffect(() => {
    checkStorage()
    return () => {}
  }, [idtoken, checkStorage])

  //PKCE  GET TOKEN FLOW
  {
    /*const { accesstoken } = useSelector(
    ({ auth }) => ({
      accesstoken: auth.pkceaccesstoken,
    }),
    shallowEqual
  )

  const checkStorage = useCallback(() => {
    setLoading(true)
    if (localStorage.getItem('pkce_access_token')) {
      setisLogged(true)
      const data = jwt_decode(accesstoken?.access_token)
      const userdata = data
      setUserInfo(userdata)
    } else {
      setisLogged(false)
      //   setTimeout(() => {
      //     handleLogin();
      //   }, 100);
    }
    setLoading(false)
  }, [accesstoken?.access_token])

  useEffect(() => {
    checkStorage()
    return () => {}
  }, [accesstoken, checkStorage])*/
  }

  return (
    <ThemeConfig>
      <NavigationScroll>
        <LoadingSplashScreen />
        {!loading && <Routes isLogged={isLogged} userInfo={userInfo} />}
        <ToastContainer toastStyle={{ color: '#248cd4' }} />
      </NavigationScroll>
    </ThemeConfig>
  )
}

export default App
