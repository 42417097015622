import axios from 'axios'

// @react-router
import { logoutUser } from '../features/auth/redux/authAction'
import { store } from '../store'
import { hideLoader, showLoader } from '../store/loader/actions'
import getToken from '../utils/getToken'

// export default function axiosInterceptorHandler() {
/**
 * Axios Interceptor to handle refresh token
 */

// let isAlreadyFetching = false
// let subscriber = []

// function onAccessTokenFetched(access_token) {
//   subscriber = subscriber.filter(callback => callback(access_token))
// }

// function addSubscriber(callback) {
//   subscriber.push(callback)
// }

const instance = axios.create()
instance.interceptors.request.use(
  config => {
    store.dispatch(showLoader())
    let token = localStorage.getItem('access_token')
    //  config.headers = { ...getToken().headers, ...config.headers }
    config.headers = getToken().headers
    // console.log('config: ' + config.headers)
    return config
  },
  err => Promise.reject(err)
)

export const instanceVideoFile = axios.create()
instance.interceptors.request.use(
  config => {
    store.dispatch(showLoader())
    let token = localStorage.getItem('access_token')

    config.headers = getToken('video/mp4').headers
    // console.log('config: ' + config.headers)
    return config
  },
  err => Promise.reject(err)
)

instance.interceptors.response.use(
  response => {
    store.dispatch(hideLoader())
    return response
  },
  error => {
    const {
      // eslint-disable-next-line no-unused-vars
      config,
      response: { status },
    } = error
    // const originalRequest = config
    store.dispatch(hideLoader())
    if (status === 401) {
      localStorage.removeItem('cognito_access_token')
      localStorage.removeItem('cognito_id_token')
      localStorage.removeItem('cognito_refresh_token')
      localStorage.removeItem('enum_data')
      window.location.href = `${process.env.REACT_APP_AWS_COGNITO}/logout?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENTID}&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI}`
      // store.dispatch(logoutUser())
      // if (!isAlreadyFetching) {
      //   const retryOriginalRequest = new Promise(resolve => {
      //     addSubscriber(access_token => {
      //       //  console.log(access_token)
      //       //  let token = localStorage.getItem("access_token")
      //       //  console.log("new Token", token)
      //       originalRequest.headers.Authorization = `Bearer ${access_token}`
      //       resolve(instance(originalRequest))

      //      // retryOriginalRequest()

      //     })
      //   })
      //   isAlreadyFetching = true
      // return store.dispatch(RefreshTokenAction()).then(res => {

      //     isAlreadyFetching = false
      //     let access_token = res?.data?.access_token
      //     console.log('Access token',access_token)
      //     onAccessTokenFetched(access_token)
      //     return retryOriginalRequest
      //   })
      // }

      // const retryOriginalRequest = new Promise(resolve => {
      //   addSubscriber(access_token => {
      //     //  console.log(access_token)
      //     //  let token = localStorage.getItem("access_token")
      //     //  console.log("new Token", token)
      //     originalRequest.headers.Authorization = `Bearer ${access_token}`
      //     resolve(axios(originalRequest))
      //   })
      // })

      // return retryOriginalRequest
    }

    return Promise.reject(error)
  }
)
export default instance
//}
