import __env from '../../../env'
import axios from 'axios'
import getToken from '../../../utils/getToken'
import instance from '../../../middleware/axiosInstance'

//............Category Api.........//

export function getAction(pageable) {
  // const { headers } = getToken()
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/list/paging?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&search=${pageable.search}`
  )
}

export function getCategoryList() {
  // const { headers } = getToken()
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/list`
  )
}

export function postCategory(data) {
  // const { headers } = getToken()

  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category`,
    data
  )
}

export function updateCategory(id, data) {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/${id}`,
    { categoryName: data }
  )
}

export function deleteCategory(id) {
  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/${id}`
  )
}

//.........Vender Api.......//

export function postVendor(data) {
  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/vendor`,
    data
  )
}

export function getVendor() {
  return instance.get(`${process.env.REACT_APP_BACKEND_URL}/master-data/vendor`)
}

export function updateVendor(id, data) {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/vendor/${id}`,
    data
  )
}

export function deleteVendor(id) {
  // const { headers } = getToken()

  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/vendor/${id}`
  )
}

//.........SubCategoryApi.......//

export function getsubCategory() {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/subCategory/list`
  )
}

export function getSubCategoryList(pageable) {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/subCategory/list/paging?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&search=${pageable.search}`
  )
}

export function postApi(id, data) {
  return instance.post(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/subCategory/${id}`,
    data
  )
}

export function updateSubCategry(id, data) {
  return instance.put(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/subCategory/${id}`,
    data
  )
}

export function deleteSubCategry(id) {
  return instance.delete(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/category/subCategory/${id}`
  )
}
//========get vendor crud pagination========//
export function getVendorAllList(pageable) {
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/master-data/vendor/list/paging?pageNo=${pageable.pageNo}&pageSize=${pageable.pageSize}&sortBy=${pageable.sortBy}&sortDir=${pageable.sortDir}&search=${pageable.search}`
  )
}
