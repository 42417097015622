// theme constant
export const gridSpacing = 3
export const drawerWidth = 260
export const appDrawerWidth = 320

export const MESSAGE_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
}
