import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants/TemplateConstant'

const initialState = {
  get_topics: [],
  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
  get_single_topic: null,
  trendingTopics: null,
}

const topicSlice = createSlice({
  name: 'topicSlice',
  initialState: initialState,
  reducers: {
    sendTrendingTopics(state, action) {
      state.trendingTopics = action.payload
    },
    getTrendingTopics(state, action) {
      state.get_topics = action.payload.response.data.content
      state.pageable = {
        ...state.pageable,
        pageNo: action.payload.response.data.pageNo,
        pageSize: action.payload.response.data.pageSize,
        totalElements: action.payload.response.data.totalElements,
        totalPages: action.payload.response.data.totalPages,
      }
    },
    getSingleTrendingTopic(state, action) {
      state.get_single_topic = action.payload
    },
  },
})
export default topicSlice
