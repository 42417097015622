import * as requestFromServer from './categoryCrud'
import { appSlice } from './categorySlice'
import jwt_decode from 'jwt-decode'
import { toast } from 'react-toastify'
import { Pagination } from '@mui/material'

const { actions } = appSlice

//==========GET Category with pagination=========//

export const getCategory = pageable => dispatch => {
  return requestFromServer
    .getAction(pageable)
    .then(response => {
      const appResponse = response.data
      dispatch(actions.getCategory({ appResponse }))
    })
    .catch(error => {})
}

//==========GET Category=========//
export const getCategoryAction = () => dispatch => {
  return requestFromServer
    .getCategoryList()
    .then(response => {
      const appResponse = response.data
      dispatch(actions.getAllCategories({ appResponse }))
    })
    .catch(error => {})
}

export const postAction = data => dispatch => {
  return requestFromServer
    .postCategory(data)
    .then(response => {
      const appResponse = response
      return appResponse
    })
    .catch(error => {
      return error
    })
}
export const updateAction = (id, data, setFlag) => dispatch => {
  return requestFromServer
    .updateCategory(id, data)
    .then(response => {
      const appResponse = response
      return appResponse
    })
    .catch(error => {
      return error
    })
}

export const deleteAction = id => dispatch => {
  return requestFromServer
    .deleteCategory(id)
    .then(response => {
      const appResponse = response
      return appResponse
    })
    .catch(error => {
      return error
    })
}

//==========Vendor Api=========//

export const postVendor = data => dispatch => {
  return requestFromServer
    .postVendor(data)
    .then(response => {
      const appResponse = response
      return appResponse
    })
    .catch(error => {
      return error
    })
}

export const getVendorList = token => dispatch => {
  return requestFromServer
    .getVendor(token)
    .then(response => {
      const appResponse = response.data
      dispatch(actions.getVendorList({ appResponse }))
    })
    .catch(error => {})
}

export const deleteVendorAction = id => dispatch => {
  return requestFromServer
    .deleteVendor(id)
    .then(response => {
      const appResponse = response
      return appResponse
    })
    .catch(error => {})
}

export const updateVendorAction = (id, data) => dispatch => {
  return requestFromServer
    .updateVendor(id, data)
    .then(response => {})
    .catch(error => {})
}

//==========Get SubCategory Api=========//
export const getSubCategoryAction = () => dispatch => {
  return requestFromServer
    .getsubCategory()
    .then(response => {
      const appResponse = response.data
      dispatch(
        actions.getsubCategory({
          appResponse,
        })
      )
    })
    .catch(error => {})
}

//==========Get SubCategory with pagination=========//
export const getAllSubCategory = pageable => dispatch => {
  return requestFromServer
    .getSubCategoryList(pageable)
    .then(response => {
      const appResponse = response.data
      dispatch(
        actions.getSubCategoryData({
          appResponse,
        })
      )
    })
    .catch(error => {})
}

export const postSubCategory = (id, data) => dispatch => {
  return requestFromServer
    .postApi(id, data)
    .then(response => {
      const appResponse = response
      return appResponse
    })
    .catch(error => {
      return error
    })
}

export const updateSubCategoryAction = (id, data) => dispatch => {
  return requestFromServer
    .updateSubCategry(id, data)
    .then(response => {
      const appResponse = response
      return appResponse
    })
    .catch(error => {
      return error
    })
}

export const deleteSubCategoryAction = id => dispatch => {
  return requestFromServer
    .deleteSubCategry(id)
    .then(response => {
      const appResponse = response
      return appResponse
    })
    .catch(error => {
      return error
    })
}

//===========Getvendor with pagination=====//

export const getAllVendor = pageable => dispatch => {
  return requestFromServer
    .getVendorAllList(pageable)
    .then(response => {
      const appResponse = response.data
      dispatch(
        actions.getVendorData({
          appResponse,
        })
      )
    })
    .catch(error => {})
}
