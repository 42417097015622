import FileUploadIcon from '@mui/icons-material/FileUpload'
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import getToken from '../../../../utils/getToken'
import instance, {
  instanceVideoFile,
} from '../../../../middleware/axiosInstance'
import { formatFileSize } from '../../../../utils/helperFunctions'
import { secondsToHms } from '../../../../utils/helperFunctions'

const fileTypes = ['MP4', 'MOV']
function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="white"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

const FileUpload = () => {
  const navigate = useNavigate()
  // const {video, thumbnail, description} = upload_video
  const [file, setFile] = useState(null)
  const [progress, setProgress] = React.useState(0)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [videoPrev, setVideoPrev] = useState(null)
  const [videoError, setVideoError] = useState(null)
  const [videoDuration, setVideoDuration] = useState(null)

  const { headers } = getToken()

  useEffect(() => {
    if (videoDuration) {
      videoData(videoDuration)
    }
  }, [videoDuration])

  const handleChange = file => {
    setVideoError('')
    const videoEl = document.createElement('video')
    videoEl.src = window.URL.createObjectURL(file)
    setFile(file)
    // When the video metadata has loaded, check
    // the video width/height
    videoEl.onloadedmetadata = event => {
      window.URL.revokeObjectURL(videoEl.src)
      const { videoWidth, videoHeight, duration } = videoEl
      setVideoDuration(duration)
    }
  }
  const videoData = duration => {
    if (duration < 5) {
      setVideoError('Duration should not less than 5sec ')
      return
    }

    const url_ = URL.createObjectURL(file)
    setVideoPrev(url_)
    const id = localStorage.getItem('assetId')
    const url = `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip/uploader?id=${id}&fileName=${file?.name}`
    var config = {
      method: 'put',
      url: url,
    }
    if (videoError) {
      return videoError
    } else {
      setSuccess(false)
      setLoading(true)
      instance(config)
        .then(resp => {
          if (resp?.status === 200) {
            const success = resp?.data
            toast.warning(success, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            })
            setSuccess(true)
            setLoading(false)
            setProgress(0)
          }

          const videoUrl = resp?.data?.uploadUrl

          uploadVideoFileToS3(videoUrl, file)
        })
        .catch(err => {
          toast.error('Something went wrong!', {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          })
        })
      setLoading(false)
    }
  }

  //   /* =========Api implementation for video upload ========= */
  const uploadVideoFileToS3 = (videoUrl, file) => {
    const type = file?.type

    return new Promise((resolve, reject) => {
      instanceVideoFile
        .put(videoUrl, file, {
          headers: {
            'Content-Type': type,
          },
          onUploadProgress: file => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * file.loaded) / file.total))
          },
        })
        .then(response => {
          if (response.status === 200) {
            toast.success('Successfully Uploaded Video!', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            })
            setProgress(0)
            navigate('/assets/list', { state: { file } })
          } else {
            toast.error('Something went wrong', {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            })
          }
        })
    })
  }

  return (
    <>
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Card>
            <div className="App">
              <Box
                sx={{
                  display: 'flex',
                  height: '50%',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  border: '1px dashed #1e1e2d',
                  borderRadius: '10px',
                }}
              >
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  // onSizeError={handleSize}
                  // dropMessageStyle={handleMessages}
                >
                  <Stack
                    spacing={4}
                    justifyContent="center"
                    alignItems="center"
                    p={4}
                  >
                    <Box
                      sx={{
                        borderRadius: '50%',
                        backgroundColor: '#E5E5E5',
                        height: '100px',
                        width: '100px',
                        textAlign: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FileUploadIcon
                        sx={{ fontSize: '60px', color: '#AAA' }}
                      />
                    </Box>
                    <Typography variant="h4" display="block">
                      Drag and drop to upload
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={loading}
                    >
                      {progress > 0 ? (
                        <CircularProgressWithLabel
                          value={progress}
                          style={{ color: 'white' }}
                        />
                      ) : loading ? (
                        <CircularProgress />
                      ) : (
                        'Browse'
                      )}
                    </Button>
                  </Stack>
                </FileUploader>
                {videoError && <div style={{ color: 'red' }}>{videoError}</div>}
              </Box>

              <div style={{ marginTop: '20px' }}>
                {file && `File name: ${file?.name}`}
                <br />
                {file && `File type: ${file?.type}`}
                <br />
                {file && `File duration: ${secondsToHms(videoDuration)}`}
                <br />
                {file && `File size: ${formatFileSize(file?.size)}`}
                <br />
              </div>
            </div>
          </Card>
        </Paper>
      </Grid>
    </>
  )
}

export default FileUpload
