import React from 'react'
import { FormikStep, FormikStepper } from '../../../ui-components/mui-stepper'
import FileUpload from './components/FileUpload'
import TagsForm from './components/TagsForm'
import MainCard from '../../../components/cards/MainCard'

const AssetForm = ({ assetData, handleSubmit, isEdit = false, ...props }) => {
  function isEmpty(obj) {
    return Object.keys(obj).length === 0
  }
  return (
    <FormikStepper enableReinitialize={true}>
      <FormikStep
        label="Metadata"
        initialValues={{ ...assetData }}
        validate={values => {
          const errors = {}

          if (!values.name) {
            errors.name = 'Name is required'
          }
          if (!values.batch) {
            errors.batch = 'Batch is required'
          }
          if (values.tags.length == 0) {
            errors.tags = 'Tag is required'
          }
          if (isEmpty(values.categories)) {
            errors.categories = 'Please add atleast one category'
          }
          return errors
        }}
        onSubmit={values => {
          return handleSubmit(values)
        }}
      >
        <MainCard sx={{ minHeight: '50vh' }}>
          <TagsForm />
        </MainCard>
      </FormikStep>
      <FormikStep
        label="File Upload"
        onSubmit={values => {
          return false
        }}
      >
        <FileUpload />
      </FormikStep>
    </FormikStepper>
  )
}

export default AssetForm
