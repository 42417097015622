import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Link } from 'react-router-dom'

// material-ui
import { useTheme, styled } from '@mui/material/styles'
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  alpha,
} from '@mui/material'

// project imports

// assets
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const ListItemStyle = styled(props => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.secondary.main,
  },
}))

const NavItem = ({ item, level, active }) => {
  const theme = useTheme()
  const isActiveRoot = active(item.url)
  const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    '&:before': { display: 'block' },
  }

  const Icon = item.icon
  //   const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const itemIcon = item?.icon ? (
    <Icon
      stroke={1.5}
      size="1.3rem"
      sx={{
        color: isActiveRoot ? theme.palette.primary.main : 'inherit',
      }}
    />
  ) : (
    <FiberManualRecordIcon
      sx={{
        width: isActiveRoot ? 8 : 6,
        height: isActiveRoot ? 8 : 6,
      }}
      fontSize={level > 0 ? 'inherit' : 'medium'}
    />
  )

  let itemTarget = '_self'
  if (item.target) {
    itemTarget = '_blank'
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={`${item.url}`} target={itemTarget} />
    )),
  }
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget }
  }

  const itemHandler = id => {
    // dispatch(activeItem({ openItem: [id] }));
    // if (matchesSM) dispatch(openDrawer({ drawerOpen: false })); //dispatch({ type: SET_MENU, opened: false });
  }

  // active menu item on page load
  //   useEffect(() => {
  //     const currentIndex = document.location.pathname
  //       .toString()
  //       .split("/")
  //       .findIndex((id) => id === item.id);
  //     if (currentIndex > -1) {
  //       dispatch(activeItem({ openItem: [item.id] }));
  //     }
  //     // eslint-disable-next-line
  //   }, []);

  return (
    <ListItemStyle
      {...listItemProps}
      disabled={item.disabled}
      sx={{
        mb: 0.5,
        alignItems: 'flex-start',
        backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
        py: level > 1 ? 1 : 1.25,
        pl: `${level * 24}px`,
        ...(isActiveRoot && activeRootStyle),
      }}
      selected={isActiveRoot}
      onClick={() => itemHandler(item.id)}
    >
      <ListItemIcon
        sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}
        color="inherit"
      >
        {itemIcon}
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography
            variant={isActiveRoot ? 'h5' : 'body1'}
            color="inherit"
            sx={{ fontWeight: 500, pt: 0.5 }}
          >
            {item.title}
          </Typography>
        }
        secondary={
          item.caption && (
            <Typography
              variant="caption"
              sx={{ ...theme.typography.subMenuCaption }}
              display="block"
              gutterBottom
            >
              {item.caption}
            </Typography>
          )
        }
      />
      {item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemStyle>
  )
}

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number,
  active: PropTypes.func,
}

export default NavItem
