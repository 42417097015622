import { Button, Divider, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { Field, Formik, ErrorMessage } from 'formik'
import FormikControl from './FormikControl'
import * as Bs from 'react-icons/bs'

const KeyValueField = ({
  namespace,
  index,
  valueType,
  subFormIk,
  handleChange,
}) => {
  useEffect(() => {
    handleChange(subFormIk.values[namespace])
  }, [subFormIk.values[namespace]])

  const handleDelete = () => {
    subFormIk.setFieldValue(
      namespace,
      subFormIk.values[namespace].filter((_, i) => i !== index)
    )
  }

  return (
    <Stack direction="row" spacing={1} sx={{ marginBottom: '20px' }}>
      <FormikControl
        control="input"
        type="text"
        label="Key"
        name={`${namespace}.${index}.key`}
      />
      <FormikControl
        control="tags"
        type={valueType}
        label="Value"
        name={`${namespace}.${index}.value`}
      />
      <IconButton onClick={() => handleDelete()}>
        <Bs.BsTrash />
      </IconButton>
    </Stack>
  )
}

const ObjectField = props => {
  const { label, name, valueType = 'text', ...rest } = props

  const handleAdd = (subFormIk, { form, field }) => {
    const currentValues = subFormIk.values[name]
    const newValue = { key: '', value: [] }

    if (Array.isArray(currentValues)) {
      subFormIk.setFieldValue(name, [...currentValues, newValue])
    } else {
      subFormIk.setFieldValue(name, [newValue])
    }
  }

  const handleChange = (values, field, form) => {
    form.setFieldValue(
      name,
      values.reduce((acc, { key, value }) => {
        acc[key] = value
        return acc
      }, {})
    )
  }

  return (
    <Field name={name}>
      {({ field, form, meta }) => (
        <>
          <Formik
            initialValues={{
              [name]:
                field.value &&
                Object.keys(field.value).map(k => ({
                  key: k,
                  value: field.value[k],
                })),
            }}
          >
            {subFormIk => (
              <>
                {/* <span>{JSON.stringify(subFormIk)}</span> */}
                <Stack>
                  {subFormIk?.values[name]?.length == 0
                    ? `No ${name} added.`
                    : subFormIk.values[name]?.map((dto, index) => (
                        <>
                          <KeyValueField
                            key={index}
                            index={index}
                            dto={dto}
                            valueType={valueType}
                            namespace={`${name}`}
                            subFormIk={subFormIk}
                            handleChange={values =>
                              handleChange(values, field, form)
                            }
                          />
                        </>
                      ))}
                </Stack>
                <Stack direction="row" justifyContent="flex-start">
                  <Typography variant="h5"></Typography>
                  <Button
                    onClick={() => handleAdd(subFormIk, { form, field })}
                    startIcon={<AddIcon />}
                    variant="contained"
                    size="medium"
                    sx={{ marginTop: '20px', marginBottom: '20px' }}
                  >
                    {label}
                  </Button>
                </Stack>
              </>
            )}
          </Formik>
        </>
      )}
    </Field>
  )
}

export default ObjectField
