function getToken(contentType) {
  // const token = localStorage.getItem('pkce_access_token')
  const token = localStorage.getItem('cognito_id_token')
  let headers = {
    'Content-Type': contentType ? contentType : 'application/json',
    Authorization: `Bearer ${token}`,
  }
  if (!token) {
    headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    }
  }

  return { token: token, headers: headers }
}

export default getToken
