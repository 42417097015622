import { createSlice } from '@reduxjs/toolkit'
import {
  defaultPage,
  defaultSize,
  defaultSortDir,
  defaultTotalElements,
  defaultSortBy,
  defaultTotalPages,
} from '../../../constants/index'

const initialState = {
 getSchema: [],
 getSingleSchema:null,
 schemaPagination:[],
 getCustomerList:[],
 customerPaginationList:[],
 getSingleCustomer:null,
  pageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },

  customerPageable: {
    pageNo: defaultPage,
    pageSize: defaultSize,
    sortBy: defaultSortBy,
    sortDir: defaultSortDir,
    totalPages: defaultTotalPages,
    totalElements: defaultTotalElements,
  },
}

const schemaSlice = createSlice({
    name: 'schema',
    initialState: initialState,
    reducers: {
   schemaFetched: (state, action) => {
        state.getSchema = action.payload
      },

    schemaFetchedById: (state, action) => {
        state.getSingleSchema = action.payload
      },
     schemaPaginationFetched: (state, action) => {
        state.schemaPagination = action.payload.content
        state.pageable = {
          ...state.pageable,
          pageNo: action.payload.pageNo,
          pageSize: action.payload.pageSize,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
        }
      },
      customerFetched:(state,action)=>{
        state.getCustomerList=action.payload
      },

     customerPaginationFetched: (state, action) => {
        state.customerPaginationList = action.payload.content
        state.customerPageable = {
          ...state.customerPageable,
          pageNo: action.payload.pageNo,
          pageSize: action.payload.pageSize,
          totalElements: action.payload.totalElements,
          totalPages: action.payload.totalPages,
        }
      },
      customerFetchedById: (state, action) => {
        state.getSingleCustomer = action.payload
      },
    
    },
  })
  
  export default schemaSlice