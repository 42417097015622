import axios from 'axios'
import __env from '../../../env'
import jwt_decode from 'jwt-decode'
import instance from '../../../middleware/axiosInstance'
import getToken from '../../../utils/getToken'

const api_url = `${process.env.REACT_APP_URL}`

{
  /*let headers = {
  'content-type': 'application/x-www-form-urlencoded',
}
*/
}

/* ========= AWS COGNITO ========= */
export function CognitoLogin() {
  return instance.get(
    `${process.env.REACT_APP_AWS_COGNITO}/login?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENTID}&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI}`
  )
}

export function CognitoGetAccessToken(data) {
  return instance.post(
    `${process.env.REACT_APP_AWS_COGNITO}/oauth2/token`,
    data
  )
}

export function Cognitologout() {
  return instance.get(
    `${process.env.REACT_APP_AWS_COGNITO}/logout?response_type=code&client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENTID}&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI}`
  )
}

export function PkceLogin(query) {
  return instance.get(
    `${process.env.REACT_APP_URL}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/auth${query}`
  )
}

export function PkceGetAccessToken(data) {
  return instance.post(
    `${process.env.REACT_APP_URL}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/token`,
    data
  )
}

export function RefreshToken() {
  const client_id = process.env.REACT_APP_AWS_COGNITO_CLIENTID
  const refresh_token = localStorage.getItem('cognito_refresh_token')
  const client_secret = process.env.REACT_APP_AWS_COGNITO_CLIENTSECRET
  const urlencoded = new URLSearchParams()
  urlencoded.append('grant_type', refresh_token)
  urlencoded.append('client_id', client_id)
  urlencoded.append('client_secret', client_secret)
  urlencoded.append('refresh_token', refresh_token)

  return instance.post(`${process.env.REACT_APP_URL}/oauth2/token`, urlencoded)
}

export function logOutUser() {
  const id_token = localStorage.getItem('pkce_id_token')
  return instance.get(
    `${process.env.REACT_APP_URL}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/logout?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENTID}&post_logout_redirect_uri=${process.env.REACT_APP_KEYCLOAK_POST_LOGOUT_URI}&id_token_hint=${id_token}`
  )
}

/* =========Get admin token ========= */
export function EnumData() {
  const { headers } = getToken()
  return instance.get(
    `${process.env.REACT_APP_BACKEND_URL}/whilter-owned/video-clip/data`,
    { headers }
  )
}

/* ========= Getuser ========= */
export function userProfile() {
  const state = {
    userdata: localStorage.getItem('access_token')
      ? jwt_decode(localStorage.getItem('access_token'))
      : null,
  }
  const id = state?.userdata?.sub
  const token = localStorage.getItem('admin_token')
  try {
    if (token) {
      {
        /*  headers['Authorization'] = `Bearer ${token}`*/
      }
      return instance.get(
        `${process.env.REACT_APP_URL}/admin/realms/${process.env.REACT_APP_REALM}/users/${id}`
      )
    }
  } catch (error) {}
}

/* ========= Login ========= */
export function login(formData) {
  return instance.post(
    `${process.env.REACT_APP_URL}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/token`,
    formData
  )
}

/* ========= Signup ========= */
export function register(formData) {
  const token = localStorage.getItem('admin_token')

  return instance.post(
    `${process.env.REACT_APP_URL}/admin/realms/${process.env.REACT_APP_REALM}/users`,
    formData,
    {
      /* {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }*/
    }
  )
}

// export function getUserByToken() {
//   // Authorization head should be fulfilled in interceptor.
//   return axios.get(ME_URL);
// }

export function PasswordChange(formData) {
  return instance.post(`${api_url}/auth/create-retailer?appId=${''}`, formData)
}

export function PasswordReset(authToken, username, password) {
  return instance.post(
    `https://b688-2401-4900-1f33-ff25-b911-1324-e01d-6b32.in.ngrok.io/api/users/password?password=${password}@&username=${username}`,
    {},

    { headers: { Authorization: `Bearer ${authToken || ''}` } }
  )
}
