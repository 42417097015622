import { createSlice } from '@reduxjs/toolkit'
const auth = !!localStorage.getItem('isAuthenticated')

const initialState = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: auth,
  enumdetails: null,
  user: null,
  userinfo: null,
  googleUser: null,
  facebookUser: null,
  loading: false,
  success: '',
  error: null,
  resetPasswordData: null,
  loginPage: null,
  pkcestatevalue: null,
  pkcecodeverifiervalue: null,
  cognitoaccesstoken: null,
  cognitoidtoken: null,
  cognitorefreshtoken: null,
  pkceuserprofile: null,
  sessionTime: null,
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState: initialState,
  reducers: {
    getAccessToken(state, action) {
      localStorage.setItem('access_token', action.payload.access_token)
      state.accessToken = action.payload.access_token
    },
    UserDetails(state, action) {
      state.googleUser = action.payload
    },
    pkceState(state, action) {
      localStorage.setItem('pkceState', action.payload)
      state.pkcestatevalue = action.payload
    },
    pkceUserProfile(state, action) {
      state.pkceuserprofile = action.payload
    },
    pkceCodeVerifier(state, action) {
      localStorage.setItem('codeVerifier', action.payload)
      state.pkcecodeverifiervalue = action.payload
    },
    congitoAccessToken(state, action) {
      localStorage.setItem('cognito_access_token', action.payload.access_token)
      state.cognitoaccesstoken = action.payload
    },
    congitoRefreshToken(state, action) {
      localStorage.setItem(
        'cognito_refresh_token',
        action.payload.refresh_token
      )
      state.cognitorefreshtoken = action.payload.refresh_token
    },
    getIdToken(state, action) {
      localStorage.setItem('cognito_id_token', action.payload.id_token)
      state.cognitoidtoken = action.payload
    },
    facebookUserDetails(state, action) {
      state.facebookUser = action.payload
    },
    getUser(state, action) {
      localStorage.getItem('access_token')
      state.user = action.payload
    },
    userInfo(state, action) {
      state.userinfo = action.payload
    },
    enumdata(state, action) {
      localStorage.setItem('enum_data', action.payload)
      state.enumdetails = localStorage.getItem('enum_data')
    },

    // sessionTime(state, action) {
    //   const expiresAt = action.payload.expires_in;
    //   localStorage.setItem("session_time", expiresAt);
    //   state.sessionTime = action.payload;
    // },

    isAuthenticated(state, action) {
      state.isAuthenticated = !state.isAuthenticated
      localStorage.setItem('isAuthenticated', state.isAuthenticated)
    },
    //  const expiresAt= localStorage.getItem("expireAt")
    //   const isNotExpired = new Date().getTime() < expiresAt;
    //   if (isNotExpired) {
    //     localStorage.setItem("isAuthenticated", true);
    //   }
    //   return false;

    removeAccess(state, action) {
      state.accessToken = null
      state.refreshToken = null
      state.user = null
      state.userinfo = null
      state.googleUser = null
      state.facebookUser = null
      state.pkcestatevalue = null
      state.pkcecodeverifiervalue = null
      state.pkceaccesstoken = null
      state.pkceidtoken = null
      state.isAuthenticated = false
      state.enumdetails = null
      state.cognitoaccesstoken = null
      state.cognitoidtoken = null
      state.cognitorefreshtoken = null
      localStorage.removeItem('access_token')
      localStorage.removeItem('pkce_refresh_token')
      localStorage.removeItem('isAuthenticated')
      localStorage.removeItem('pkce_access_token')
      localStorage.removeItem('pkce_id_token')
      localStorage.removeItem('pkceState')
      localStorage.removeItem('codeVerifier')
      localStorage.removeItem('cognito_access_token')
      localStorage.removeItem('cognito_id_token')
      localStorage.removeItem('cognito_refresh_token')
    },
    passwordData: (state, action) => {
      state.loading = false
      state.success = action.payload.success
      state.resetPasswordData = action.payload.passwordResponse
      state.error = null
    },
    resetPasswordData: (state, action) => {
      state.loading = false
      state.success = action.payload.success
      state.resetPasswordData = action.payload.resetPasswordResponse
      state.error = null
    },
    getPage: (state, action) => {
      state.loginPage = action.payload
    },
  },
})

export default authSlice
